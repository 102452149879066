import React from 'react'
import {
  eFerretSearchResult,
  InteractiveSearchResultsProps
} from './interfaces'
import { Theme, Grid } from '@material-ui/core'
import { makeStyles, createStyles } from '@material-ui/styles'
import ReactGA from 'react-ga'
import InteractiveSearchResultCard from './InteractiveSearchResultCard'

ReactGA.initialize('UA-106571309-2', {
  gaOptions: {
    sampleRate: 100
  }
})

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    results: {
      maxWidth: '100%'
    }
  })
)

const InteractiveSearchResults: React.FC<InteractiveSearchResultsProps> = (props) => {
  const classes = useStyles()
  return (
    <Grid item container className={classes.results} justify='center' spacing={1} xs={12}>
      {
        props.results === null ?
          ''
          :
          props.results.map((searchResult: eFerretSearchResult, index) => (
            <Grid item md={props.halfWidth? 6 : 4} lg={props.halfWidth ? 4 : 3} key={index}>
              <InteractiveSearchResultCard result={searchResult} key={index} />
            </Grid>
          ))
      }
    </Grid>
  )
}

export default InteractiveSearchResults