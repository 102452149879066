import React from 'react'
import {
  Button,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  makeStyles,
  Snackbar,
  TextField,
  Theme,
  Typography
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { sendFeedback } from './utilities'

interface FeedbackFormProps {
  open: boolean
  handleClose: () => void
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      width: 350
    },
    spaceBelow: {
      marginBottom: '1em',
      width: '100%'
    },
    close: {
      padding: theme.spacing(0.5)
    }
  })
)

const FeedbackForm: React.FC<FeedbackFormProps> = (props) => {
  const classes = useStyles()
  const [feedback, setFeedback] = React.useState('')
  const [showSnackbar, setShowSnackbar] = React.useState(false)

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    setFeedback(event.target.value)
  }

  function submitFeedback() {
    sendFeedback("Web: " + feedback).then((res) => {
      console.log('Sent feedback. Response:')
      console.log(res.data)
      setShowSnackbar(true)
      props.handleClose()
    })
  }

  function handleCloseSnackbar(event: React.SyntheticEvent | React.MouseEvent, reason?: string) {
    if (reason === 'clickaway') {
      return
    }

    setShowSnackbar(false)
  }

  return (
    <div>
      <Dialog open={props.open} onClose={props.handleClose}>
        <DialogTitle>Feedback</DialogTitle>
        <DialogContent className={classes.content}>
          <TextField
            className={classes.spaceBelow}
            multiline
            rowsMax='6'
            rows='6'
            value={feedback}
            onChange={handleChange}
            variant='outlined' />
          <Typography variant='caption'>
            All feedback is anonymous. This is a great place to submit feature requests, but if you've encountered a
            bug,
            please instead send an email to robert@ferretech.io.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.handleClose}>
            Cancel
          </Button>
          <Button onClick={submitFeedback}>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        open={showSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        ContentProps={{
          'aria-describedby': 'message-id'
        }}
        message={<span id="message-id">Thank you for your feedback! 🤗</span>}
        action={[
          <IconButton
            key="close"
            aria-label="close"
            color="inherit"
            className={classes.close}
            onClick={handleCloseSnackbar}
          >
            <CloseIcon />
          </IconButton>
        ]}
      />
    </div>
  )
}

export default FeedbackForm