import React from 'react'
import {
  Button,
  createStyles,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  Snackbar,
  Theme,
  Typography,
  TextField
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'

interface PreferencesFormProps {
  open: boolean
  handleClose: () => void
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 250,
      overflowX: 'hidden'
    },
    formControl: {
      marginTop: '6px',
      marginBottom: '6px',
      maxWidth: '100%',
      width: '100%'
    },
    fullWidth: {
      width: '100%'
    },
    spaceAbove: {
      marginTop: '1em',
      width: '100%'
    },
    close: {
      padding: theme.spacing(0.5)
    },
    submitButton: {
      marginTop: '10px'
    }
  })
)

const PreferencesForm: React.FC<PreferencesFormProps> = (props) => {
  const classes = useStyles()
  let preferredEbaySite = localStorage.getItem('preferredSite')
  let preferredCurrency = localStorage.getItem('preferredCurrency')
  let postalCode = localStorage.getItem('postalCode')

  const [ebaySite, setEbaySite] = React.useState(preferredEbaySite || '0')
  const [currency, setCurrency] = React.useState(preferredCurrency || 'USD')
  const [locationPreferenceValue, setLocationPreferenceValue] = React.useState(postalCode || '')
  const [showSnackbar, setShowSnackbar] = React.useState(false)

  function handleSetSitePreference(event: React.ChangeEvent<any>) {
    setEbaySite(event.target.value)
    localStorage.setItem('preferredSite', event.target.value.toString())
    setShowSnackbar(true)
  }

  function handleSetCurrencyPreference(event: React.ChangeEvent<any>) {
    setCurrency(event.target.value)
    localStorage.setItem('preferredCurrency', event.target.value.toString())
    setShowSnackbar(true)
  }

  function handleTypeLocationPreference(event: React.ChangeEvent<any>) {
    setLocationPreferenceValue(event.target.value.toString())
  }

  function handleSetLocationPreference(event: React.BaseSyntheticEvent) {
    localStorage.setItem('postalCode', locationPreferenceValue)
    setShowSnackbar(true)
    event.preventDefault()
  }

  function handleCloseSnackbar(event: React.SyntheticEvent | React.MouseEvent, reason?: string) {
    if (reason === 'clickaway') {
      return
    }

    setShowSnackbar(false)
  }

  return (
    <Dialog open={props.open} onClose={props.handleClose}>
      <DialogTitle>Preferences</DialogTitle>
      <DialogContent className={classes.root}>
        <Grid container direction='column'>
          <Grid item className={classes.fullWidth}>
            <FormControl className={classes.formControl}>
              <InputLabel htmlFor='siteId'>Preferred eBay site</InputLabel>
              <Select
                value={ebaySite}
                onChange={handleSetSitePreference}
                displayEmpty
                name='siteId'
                className={classes.fullWidth}
              >
                <MenuItem value='0'>eBay United States</MenuItem>
                <MenuItem value='100'>eBay Motors</MenuItem>
                <MenuItem value='2'>eBay Canada (English)</MenuItem>
                <MenuItem value='210'>eBay Canada (French)</MenuItem>
                <MenuItem value='3'>eBay UK</MenuItem>
                <MenuItem value='15'>eBay Australia</MenuItem>
                <MenuItem value='16'>eBay Austria</MenuItem>
                <MenuItem value='23'>eBay Belgium (French)</MenuItem>
                <MenuItem value='123'>eBay Belgium (Dutch)</MenuItem>
                <MenuItem value='71'>eBay France</MenuItem>
                <MenuItem value='77'>eBay Germany</MenuItem>
                <MenuItem value='101'>eBay Italy</MenuItem>
                <MenuItem value='146'>eBay Netherlands</MenuItem>
                <MenuItem value='186'>eBay Spain</MenuItem>
                <MenuItem value='193'>eBay Switzerland</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item className={classes.fullWidth}>
            <FormControl className={classes.formControl}>
              <InputLabel htmlFor='currency'>Preferred Currency</InputLabel>
              <Select
                value={currency}
                onChange={handleSetCurrencyPreference}
                displayEmpty
                name="currency"
              >
                <MenuItem value='USD'>USD</MenuItem>
                <MenuItem value='AUD'>AUD</MenuItem>
                <MenuItem value='CAD'>CAD</MenuItem>
                <MenuItem value='CHF'>CHF</MenuItem>
                <MenuItem value='CNY'>CNY</MenuItem>
                <MenuItem value='EUR'>EUR</MenuItem>
                <MenuItem value='GBP'>GBP</MenuItem>
                <MenuItem value='HKD'>HKD</MenuItem>
                <MenuItem value='INR'>INR</MenuItem>
                <MenuItem value='MYR'>MYR</MenuItem>
                <MenuItem value='PHP'>PHP</MenuItem>
                <MenuItem value='PLN'>PLN</MenuItem>
                <MenuItem value='SEK'>SEK</MenuItem>
                <MenuItem value='SGD'>SGD</MenuItem>
                <MenuItem value='TWD'>TWD</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item className={classes.fullWidth}>
            <form onSubmit={handleSetLocationPreference}>
              <FormControl className={classes.formControl}>
                <Grid container>
                  <Grid item xs={10}>
                    <TextField label='Buyer Postal Code' value={locationPreferenceValue}
                               onChange={handleTypeLocationPreference} />
                  </Grid>
                  <Grid item xs={2}>
                    <Button type='submit' className={classes.submitButton}>Set</Button>
                  </Grid>
                </Grid>
              </FormControl>
            </form>
          </Grid>
          <Grid item className={classes.spaceAbove}>
            <Typography variant='caption'>
              Changes will affect future searches, but not existing ones.
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        open={showSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        ContentProps={{
          'aria-describedby': 'message-id'
        }}
        message={<span id="message-id">Preferences saved.</span>}
        action={[
          <IconButton
            key="close"
            aria-label="close"
            color="inherit"
            className={classes.close}
            onClick={handleCloseSnackbar}
          >
            <CloseIcon />
          </IconButton>
        ]}
      />
    </Dialog>
  )
}

export default PreferencesForm