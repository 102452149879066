import React, { BaseSyntheticEvent, useEffect } from 'react'
import FormattedPrice from './FormattedPrice'
import { makeStyles, createStyles } from '@material-ui/styles'
import { Theme, Slider } from '@material-ui/core'

interface PriceSliderProps {
  observedPrices: number[]
  observedCurrency: string
  selectedPrices: number[]
  setPrices: (prices: number[]) => void
  hasManuallySetPrice: boolean
  setHasManuallySetPrice: (has: boolean) => void
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    priceSlider: {
      marginTop: 10
    },
    priceSliderLabel: {
      marginLeft: 'auto',
      marginRight: 'auto',
      textAlign: 'center'
    },
    hyphen: {
      fontSize: '1.5em'
    }
  })
)

function PriceSlider(props: PriceSliderProps) {
  const classes = useStyles()
  const [internalPrices, setInternalPrices] = React.useState(props.selectedPrices)
  useEffect(() => {
    if (!props.hasManuallySetPrice) {
      setInternalPrices(props.observedPrices)
    }
  }, [props.observedPrices])

  let step = props.observedPrices[1] - props.observedPrices[0]
  if (step <= 10) {
    step = .01
  } else if (step <= 50) {
    step = .50
  } else if (step <= 200) {
    step = 1
  } else {
    step = 5
  }

  const handleChange = (event: BaseSyntheticEvent, newValue: number | number[]) => {
    setInternalPrices(newValue as number[])
  }

  const handleCommit = () => {
    props.setHasManuallySetPrice(true)
    props.setPrices(internalPrices)
  }

  return (
    <div>
      <Slider min={0} max={Math.ceil(props.observedPrices[1] * 1.5 / 100) * 100} step={step}
              value={internalPrices}
              onChange={handleChange}
              onChangeCommitted={handleCommit}
              valueLabelDisplay='off' className={classes.priceSlider} />
      <div className={classes.priceSliderLabel}>
        <FormattedPrice inline currency={props.observedCurrency} amount={internalPrices[0]} />
        <span className={classes.hyphen}>&nbsp;-&nbsp;</span>
        <FormattedPrice inline currency={props.observedCurrency} amount={internalPrices[1]} />
      </div>
    </div>
  )
}

export default PriceSlider