import React from 'react'
import * as utilities from './utilities'
import { makeStyles, Theme, createStyles } from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    timeListedOverlay: {
      background: '#0008',
      position: 'absolute',
      top: 0,
      right: 0,
      padding: theme.spacing(1),
      borderBottomLeftRadius: 6
    },
    timeListedOverlayBottom: {
      background: '#0008',
      position: 'absolute',
      bottom: 0,
      right: 0,
      padding: theme.spacing(1),
      borderTopLeftRadius: 6
    },
    timeListedText: {
      color: '#fff',
      fontSize: '0.8em',
      fontFamily: 'roboto'
    }
  })
)

interface TimeListedOverlayProps {
  timeListed: string
  bottom?: boolean
}

const TimeListedOverlay: React.FC<TimeListedOverlayProps> = props => {
  const classes = useStyles()

  return (
    <div className={props.bottom ? classes.timeListedOverlayBottom : classes.timeListedOverlay}>
      <span className={classes.timeListedText}>{ utilities.printTimeAgo(props.timeListed) }</span>
    </div>
  )
}

export default TimeListedOverlay