import React, { useEffect } from 'react'
import QueriesList from './QueriesList'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Tooltip,
  useMediaQuery
} from '@material-ui/core'
import Fab from '@material-ui/core/Fab'
import AddIcon from '@material-ui/icons/Add'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'

import {
  Switch,
  Route,
  Link,
  useHistory
} from 'react-router-dom'

import { RouteComponentProps } from 'react-router'

import {
  EbayAspectFilter,
  EbayGenericCategoryInfo,
  eBayQuery,
  eFerretApiRequest,
  eFerretQuery,
  eFerretResultsResponse,
  eFerretSearchResult,
  eFerretSubmitQueryRequest,
  itemFilter, MainScreenProps,
  resultsPreview
} from './interfaces'
import EditQueryForm from './EditQueryForm'
import { createStyles, makeStyles, Theme, useTheme } from '@material-ui/core/styles'
import ResultsView from './ResultsView'
import { Text } from 'react-native'
import axios from 'axios'
import * as utilities from './utilities'
import { getNumberOfNewResultsForQuery, getQueryById } from './utilities'
import ReactGA from 'react-ga'
import HeaderAppBar from './HeaderAppBar'
import LandingPage from './LandingPage'
import InteractiveSearch from './InteractiveSearch'

ReactGA.initialize('UA-106571309-2', {
  gaOptions: {
    sampleRate: 100
  }
})

axios.defaults.xsrfCookieName = 'csrftoken'
axios.defaults.xsrfHeaderName = 'X-CSRFToken'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    '@global': {
      body: {
        height: '100%',
        minHeight: '100%'
      },
      html: {
        height: '100%',
        overflowX: 'hidden'
      }
    },
    root: {
      height: '100%',
      maxHeight: '100%',
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      position: 'relative'
    },
    main: {
      flexGrow: 1,
      maxHeight: 'calc(100% - 64px)'
    },
    paper: {
      padding: '4px',
      margin: '6px',
      backgroundColor: '#fff'
    },
    fullWidth: {
      width: '100%'
    },
    fabMobile: {
      position: 'fixed',
      margin: theme.spacing(2),
      bottom: 0,
      right: 0
    },
    fab: {
      position: 'absolute',
      margin: theme.spacing(4),
      bottom: 0,
      right: 0
    },
    twoColumns: {
      position: 'relative',
      width: '100%',
      height: '100%',
      maxHeight: '100%'
    },
    fullHeight: {
      height: '100%'
    },
    upButton: {
      color: '#fff',
      padding: 0
    },
    maxHeightToolbar: {
      backgroundColor: '#4050b5',
      color: '#fff',
      maxHeight: '64px',
      padding: '8px'
    },
    advancedOptionsSwitch: {
      marginLeft: '0.5em'
    },
    titleStretchRight: {
      paddingRight: 'auto'
    }
  })
)

interface MatchProps extends RouteComponentProps<{ qid?: string }> {
}

const MainScreen: React.FC<MainScreenProps> = (props) => {
  const [showPreferencesMenu, setShowPreferencesMenu] = React.useState(false)
  const [showFeedbackMenu, setShowFeedbackMenu] = React.useState(false)

  const [showTutorial, setShowTutorial] = React.useState(false)
  const [showLandingPage, setShowLandingPage] = React.useState(props.queryData.length === 0)

  useEffect(() => {
    const timer = setInterval(() => {
      pullSearchResults()
    }, 15000)
    return () => clearInterval(timer)
  }, [])

  const classes = useStyles()
  const theme = useTheme()
  const history = useHistory()
  const useFullScreenDialog = useMediaQuery(theme.breakpoints.down('xs'))
  const currentUrl = history.location.pathname.toString()
  let selectedQuery: number | null = null

  function setSelectedQuery(qid: number | null) {
    if (qid === null) {
      history.push('/')
    } else {
      history.push('/results/' + qid)
    }
  }

  if (currentUrl.includes('/results')) {
    selectedQuery = Number.parseInt(currentUrl.split('/').pop())
  }

  const preferredCurrency = localStorage.getItem('preferredCurrency')
  const preferredSite = localStorage.getItem('preferredSite')

  const [selectValues, setSelectValues] = React.useState({
    auctionType: 0,
    currency: preferredCurrency || 'USD',
    category: '',
    siteId: preferredSite || '0'
  })

  interface TextState {
    keywords: string,
    minPrice: string,
    maxPrice: string
  }

  const [textValues, setTextValues] = React.useState<TextState>({
    keywords: '',
    minPrice: '',
    maxPrice: ''
  })

  const [switchState, setSwitchState] = React.useState({
    searchDescription: false,
    highPriority: true
  })

  const [categoryHierarchy, setCategoryHierarchy] = React.useState<EbayGenericCategoryInfo[]>([])
  const [aspectFilters, setAspectFilters] = React.useState<EbayAspectFilter[]>([])
  const [formError, setFormError] = React.useState('')
  const [showErrorDialog, setShowErrorDialog] = React.useState(false)
  const [errorDialogText, setErrorDialogText] = React.useState('')
  const [resultsPreview, setResultsPreview] = React.useState<resultsPreview | null>(null)

  function validateForm() {
    if (Number.parseFloat(textValues.minPrice) > Number.parseFloat(textValues.maxPrice)) {
      setFormError('Min price must be less than max price.')
      return false
    }
    return true
  }

  function fillFormFieldsFromQuery(query: number | null) {
    if (query === null) {
      console.log('fillFormFieldsFromQuery got null QID!')
      return
    }
    let editingQuery = getQueryById(query!)
    if (editingQuery === null) {
      console.log('Editing query is null!')
      return
    }

    let minPrice = ''
    let maxPrice = ''

    if (editingQuery.query.itemFilter !== undefined) {
      let minPriceFilter = editingQuery.query.itemFilter.filter((itemFilter) => itemFilter.name === 'MinPrice')[0]
      let maxPriceFilter = editingQuery.query.itemFilter.filter((itemFilter) => itemFilter.name === 'MaxPrice')[0]
      if (minPriceFilter !== undefined) {
        minPrice = minPriceFilter!.value
      }
      if (maxPriceFilter !== undefined) {
        maxPrice = maxPriceFilter!.value
      }
    }

    setSwitchState({
      searchDescription: editingQuery.query.descriptionSearch,
      highPriority: editingQuery.highPriority
    })

    setTextValues({
      keywords: editingQuery.query.keywords,
      minPrice: minPrice,
      maxPrice: maxPrice
    })
  }

  function submitQuery(preview = false) {
    ReactGA.event({
      category: 'Conversion',
      action: 'Submitted query'
    })
    if (preview) return
    if (!validateForm()) return
    setFormError('')
    let existingQueriesString = localStorage.getItem('queries')
    let existingQueries: eFerretQuery[] = []
    if (existingQueriesString != null) {
      existingQueries = JSON.parse(existingQueriesString)
    }

    let siteId = selectValues.siteId
    let uid = localStorage.getItem('uid')
    let highPriority = switchState.highPriority
    let itemFilters: itemFilter[] = []

    if (textValues.minPrice !== '') {
      itemFilters.push({
        name: 'MinPrice',
        value: textValues.minPrice,
        paramName: 'Currency',
        paramValue: selectValues.currency
      })
    }

    if (textValues.maxPrice !== '') {
      itemFilters.push({
        name: 'MaxPrice',
        value: textValues.maxPrice,
        paramName: 'Currency',
        paramValue: selectValues.currency
      })
    }

    let query: eBayQuery = {
      keywords: textValues.keywords,
      descriptionSearch: switchState.searchDescription,
      listingType: selectValues.auctionType,
      itemFilter: itemFilters
    }

    if (categoryHierarchy.length > 0) {
      query.categoryId = categoryHierarchy[categoryHierarchy.length - 1].id
    }

    let request: eFerretSubmitQueryRequest = {
      requestType: 'submitQuery',
      highPriority: highPriority,
      siteId: siteId,
      query: query,
      uid: uid!,
      preview: preview
    }

    const currentUrl = history.location.pathname.toString()
    if (currentUrl.includes('/edit')) {
      const editingQuery = Number.parseInt(currentUrl.split('/').pop())
      request.requestType = 'updateQuery'
      request.queryId = editingQuery
    }

    let authorizedRequest = utilities.toFlatAuthJsonRequest(request)
    console.log('authorized request:')
    console.log(authorizedRequest)
    const endpoint = '/api/'

    axios.post(endpoint, authorizedRequest.request, {headers: authorizedRequest.headers}).then((res) => {
      if (preview) {
        console.log('Got results preview:')
        console.log(res.data)
        setResultsPreview(res.data.preview)
      } else {
        console.log('Submitted query:')
        console.log(res)
        if (res.data.query_status == 'ok') {
          delete request.uid
          delete request.requestType
          let localQuery: eFerretQuery = {
            id: res.data.id,
            results: res.data.results,
            ...request
          }
          if (currentUrl.includes('/edit')) {
            // if editing query, delete the old one first
            const editingQuery = Number.parseInt(currentUrl.split('/').pop())
            existingQueries = existingQueries.filter((query) => query.id !== editingQuery)
          }
          existingQueries.push(localQuery)
          console.log('Existing queries:')
          console.log(existingQueries)
          props.setQueryData(existingQueries)
          localStorage.setItem('queries', JSON.stringify(existingQueries))

          handleClose()
        } else if (res.data.status === 'error-dialog') {
          console.log('Error dialog:')
          console.log(res.data.error_type)
          let errorType = res.data.error_type
          switch (errorType) {
            case 'error-queries-exceeded':
              setErrorDialogText('You have reached the maximum number of searches. Pause or delete another search to continue.')
              break

            case 'error-high-priority-exceeded':
              setErrorDialogText('You have reached the maximum number of high-priority searches. Pause another search or uncheck "high priority" under Advanced Options.')
              break

            case 'error-low-priority-exceeded':
              setErrorDialogText('You have reached the maximum number of regular-priority searches. Pause another search or check "high priority" under Advanced Options.')
              break

            default:
              setErrorDialogText('There was an error submitting this query. Please try again.')
          }
          setShowErrorDialog(true)
        } else {
          console.log('Error submitting query:')
          console.log(res.data.error)
          setFormError(res.data.error)
        }
      }
    })
  }

  function syncQueries() {
    console.log('Attempting to sync with server...')
    let uid = localStorage.getItem('uid')
    if (!uid) {
      console.log('No UID found, cannot sync.')
      return
    }

    let request: eFerretApiRequest = {
      requestType: 'sync',
      uid: uid!,
      timestamp: Date.now()
    }

    let authorizedRequest = utilities.toFlatAuthJsonRequest(request)
    const endpoint = '/api/'

    axios.post(endpoint, authorizedRequest.request, {headers: authorizedRequest.headers}).then((res) => {
      console.log('Response from sync request:')
      console.log(res.data)
    })
  }

  function pullSearchResults() {
    let searchStyle = localStorage.getItem('search-style')
    if (searchStyle !== null && searchStyle === 'push') {
      return
    }
    let uid = localStorage.getItem('uid')
    if (!uid) {
      console.log('No UID found, cannot pull results.')
      return
    }

    let request: eFerretApiRequest = {
      requestType: 'pullResults',
      uid: uid!,
      timestamp: Date.now()
    }

    let authorizedRequest = utilities.toFlatAuthJsonRequest(request)
    console.log('Fetching updates...')
    const endpoint = '/api/'

    axios.post(endpoint, authorizedRequest.request, {headers: authorizedRequest.headers}).then((res) => {
      console.log('Updates response:')
      console.log(res)
      if (res.data.results === undefined) {
        console.log('No results in server response.')
        return
      }
      let newResults: eFerretResultsResponse[] = res.data.results
      utilities.processNewResults(newResults, props.setQueryData)
    })
  }

  function handleClose() {
    setTimeout(() => history.push('/'), 300)
  }

  const getResults = () => {
    if (window.location.toString().includes('/search')) {
      if (resultsPreview !== undefined && resultsPreview !== null && resultsPreview.results !== undefined) {
        return resultsPreview!.results
      } else {
        return null
      }
    } else {
      if (selectedQuery === null) {
        return null
      }
      let query = utilities.getQueryById(selectedQuery!)
      if (query !== null) {
        let results = query.results!.sort(function (a: eFerretSearchResult, b: eFerretSearchResult) {
          return b.listingInfo.startTime.localeCompare(a.listingInfo.startTime)
        })
        return results.slice(0, 30) as eFerretSearchResult[]
      } else {
        return null
      }
    }
  }

  const showTwoColumns = useMediaQuery(theme.breakpoints.up('lg'))

  const formProps = {
    selectValues: selectValues,
    setSelectValues: setSelectValues,
    textValues: textValues,
    setTextValues: setTextValues,
    switchState: switchState,
    setSwitchState: setSwitchState,
    categoryHierarchy: categoryHierarchy,
    setCategoryHierarchy: setCategoryHierarchy,
    aspectFilters: aspectFilters,
    setAspectFilters: setAspectFilters,
    formError: formError,
    getResultsPreview: () => submitQuery(true)
  }

  function getNumberOfNewResults(queryData: eFerretQuery[]) {
    if (queryData.length === 0) return 0
    let countArray = queryData.map((query) => {
      return getNumberOfNewResultsForQuery(query.id)
    })
    if (countArray.length === 0) return 0
    return countArray.reduce((totalResults, numResults) => {
      return totalResults + numResults
    })
  }

  function handleAddQueryClick() {
    ReactGA.event({
      category: 'Action',
      action: 'Clicked "Add query" button'
    })
  }

  let numNewResults = getNumberOfNewResults(props.queryData)
  let title: string
  numNewResults !== 0 ?
    title = '(' + numNewResults.toString() + ') eFerret'
    :
    title = 'eFerret'
  document.title = title

  return (
    <div className={classes.root}>
      <HeaderAppBar />
      <Switch>
        <Route path='/edit/:qid' render={
          // @ts-ignore
          ({match}: MatchProps) => (
            <EditQueryForm formProps={formProps} fullscreen={useFullScreenDialog} showTwoColumns={showTwoColumns}
                           editingQuery={match.params.qid ? Number.parseInt(match.params.qid) : null}
                           handleClose={handleClose} submitQuery={submitQuery}
                           fillFormFieldsFromQuery={fillFormFieldsFromQuery} />
          )
        }>
        </Route>
        <Route path='/search'>
          {
            <InteractiveSearch setQueryData={props.setQueryData} handleClose={handleClose}
                               showTwoColumns={showTwoColumns} />
          }
        </Route>
        <Route path='/'>
          {
            props.queryData.length === 0 ?
              <LandingPage />
              :
              <Grid container className={classes.main}>
                <Grid item xs={showTwoColumns ? 6 : 12} className={showTwoColumns ? classes.twoColumns : ''}>
                  <QueriesList data={props.queryData} setQueryData={props.setQueryData} selectedQuery={selectedQuery}
                               setSelectedQuery={setSelectedQuery} fillFormFieldsFromQuery={fillFormFieldsFromQuery} />
                  <Tooltip
                    title={'Click here to add your first search'}
                    open={showTutorial}>
                    <Link to={'/search/'}><Fab className={useFullScreenDialog ? classes.fabMobile : classes.fab}
                                               color='primary'
                                               onClick={handleAddQueryClick}>
                      <AddIcon />
                    </Fab></Link>
                  </Tooltip>
                  <Dialog open={showErrorDialog}>
                    <DialogTitle>Error</DialogTitle>
                    <DialogContent><Text>{errorDialogText}</Text></DialogContent>
                    <DialogActions>
                      <Button onClick={() => setShowErrorDialog(false)}>
                        Ok
                      </Button>
                    </DialogActions>
                  </Dialog>
                  <EditQueryForm formProps={formProps}
                                 showTwoColumns={showTwoColumns} fullscreen={useFullScreenDialog}
                                 editingQuery={null}
                                 handleClose={handleClose}
                                 submitQuery={submitQuery} />
                </Grid>
                {showTwoColumns ?
                  <Grid item xs={6} className={classes.twoColumns}>
                    <ResultsView results={getResults()} twoColumns={showTwoColumns} />
                  </Grid>
                  :
                  <Dialog open={selectedQuery !== null} fullScreen>
                    <DialogTitle className={classes.maxHeightToolbar}>
                      <IconButton onClick={() => setSelectedQuery(null)}>
                        <ArrowBackIcon className={classes.upButton} />
                      </IconButton>
                      Results
                    </DialogTitle>
                    <ResultsView results={getResults()} twoColumns={showTwoColumns} />
                  </Dialog>
                }
              </Grid>
          }
        </Route>
      </Switch>
    </div>
  )
}

export default MainScreen