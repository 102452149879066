import React from 'react'
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemIcon,
  ListItemText
} from '@material-ui/core'
import { EbayAspectFilter, EbayAspectInfo, EbayAspectValueHistogram, EbayConditionInfo } from './interfaces'

interface RefinementsDialogProps {
  aspects: EbayAspectInfo[]
  conditionInfo: EbayConditionInfo[]
  showDialog: boolean
  closeDialog: () => void
  aspectFilters: EbayAspectFilter[]
  setAspectFilters: (filters: EbayAspectFilter[]) => void
  chosenAspect: null | EbayAspectInfo | 'condition'
  setChosenAspect: (aspect: null | EbayAspectInfo | 'condition') => void
  isEditingAspect: boolean
  setIsEditingAspect: (editing: boolean) => void
}


interface ChildDialogProps {
  chosenAspect: EbayAspectInfo | 'condition'
  conditionInfo: EbayConditionInfo[]
  open: boolean
  closeChildDialog: () => void
  closeParentDialog: () => void
  aspectFilters: EbayAspectFilter[]
  setAspectFilters: (filters: EbayAspectFilter[]) => void
}

const ChildDialog: React.FC<ChildDialogProps> = (props) => {
  let existingFilterName: string
  console.log('Chosen aspect:')
  console.log(props.chosenAspect)
  if (isCondition(props.chosenAspect)) {
    existingFilterName = 'condition'
  } else {
    existingFilterName = props.chosenAspect._name
  }
  let existingFilter = props.aspectFilters.filter((aspectFilter: EbayAspectFilter) => aspectFilter.aspectName === existingFilterName)[0]
  console.log('Existing filter:')
  console.log(existingFilter)
  let existingChoices: number[] = []
  if (existingFilter) {
    let availableAspectValues = []
    if (isCondition(props.chosenAspect)) {
      availableAspectValues = props.conditionInfo.map((condition) => condition.condition)
    } else {
      availableAspectValues = props.chosenAspect.valueHistogram.map((histogram) => histogram._valueName)
    }
    console.log('Available aspect values')
    console.log(availableAspectValues)
    for (let i = 0; i < existingFilter.aspectValueName.length; i++) {
      existingChoices.push(availableAspectValues.indexOf(existingFilter.aspectValueName[i]))
    }
    console.log('Existing choices:')
    console.log(existingChoices)
  }

  const [checked, setChecked] = React.useState<number[]>(existingChoices)

  const handleToggle = (value: number) => () => {
    const currentIndex = checked.indexOf(value)
    let newChecked = [...checked]

    if (currentIndex === -1) {
      newChecked.push(value)
    } else {
      newChecked.splice(currentIndex, 1)
    }
    setChecked(newChecked)
  }

  function isConditionArray(aspects: EbayConditionInfo[] | EbayAspectValueHistogram[]): aspects is EbayConditionInfo[] {
    return (aspects as EbayConditionInfo[])[0].condition !== undefined
  }

  function isCondition(aspect: EbayAspectInfo | 'condition'): aspect is 'condition' {
    return aspect === 'condition'
  }

  const applyAspect = () => {
    let chosenRefinements = []
    let refinementName: string
    if (isCondition(props.chosenAspect)) {
      refinementName = 'condition'
      chosenRefinements = props.conditionInfo.filter((condition, index) => {
        return checked.indexOf(index) !== -1
      })
    } else {
      refinementName = props.chosenAspect._name
      chosenRefinements = props.chosenAspect.valueHistogram.filter((value, index) => {
        return checked.indexOf(index) !== -1
      })
    }
    console.log('Chosen refinements: ')
    console.log(chosenRefinements)
    let newAspectFilters = Array.from(props.aspectFilters)

    // If chosen aspect filter already exists, erase it first
    newAspectFilters = newAspectFilters.filter((aspectFilter) => aspectFilter.aspectName !== refinementName)
    let chosenValues: string[] | EbayConditionInfo['condition'][]

    if (chosenRefinements.length > 0) {
      if (isConditionArray(chosenRefinements)) {
        chosenValues = chosenRefinements.map((condition) => condition.condition)
        newAspectFilters.push(
          {
            aspectName: 'condition',
            aspectValueName: chosenValues
          }
        )

      } else {
        let aspect = props.chosenAspect as EbayAspectInfo
        chosenValues = chosenRefinements.map((refinement) => refinement._valueName)
        newAspectFilters.push(
          {
            aspectName: aspect._name,
            aspectValueName: chosenValues
          }
        )
      }
    }
    props.setAspectFilters(newAspectFilters)
    console.log('New aspect filters:')
    console.log(newAspectFilters)
    props.closeChildDialog()
    props.closeParentDialog()
  }

  const simpleNumberFormat = (input: string) => {
    let number = Number(Number.parseInt(input).toPrecision(2))
    let outputString = number.toLocaleString()
    if (outputString === '1') {
      return outputString + ' item'
    }
    return outputString + ' items'
  }

  const getName = (input: EbayAspectInfo | 'condition') => {
    if (isCondition(input)) {
      return 'Condition'
    }
    return input._name
  }

  const renderOptions = (input: EbayAspectInfo | 'condition') => {
    if (isCondition(input)) {
      return props.conditionInfo.map((condition, index) =>
        <ListItem key={index} button onClick={handleToggle(index)}>
          <ListItemIcon>
            <Checkbox
              edge='start'
              checked={checked.indexOf(index) !== -1} />
          </ListItemIcon>
          <ListItemText primary={condition.condition.conditionDisplayName}
                        secondary={simpleNumberFormat(condition.count)} />
        </ListItem>
      )
    } else {
      let aspect = (props.chosenAspect as EbayAspectInfo)
      return aspect.valueHistogram.map((option, index) =>
        <ListItem key={index} button onClick={handleToggle(index)}>
          <ListItemIcon>
            <Checkbox
              edge='start'
              checked={checked.indexOf(index) !== -1} />
          </ListItemIcon>
          <ListItemText primary={option._valueName} secondary={simpleNumberFormat(option.count)} />
        </ListItem>
      )
    }
  }

  return (
    <Dialog scroll='paper' fullWidth maxWidth='sm' open={props.open} onClose={props.closeChildDialog}>
      <DialogTitle>
        {getName(props.chosenAspect)}
      </DialogTitle>
      <DialogContent>
        <List>
          {
            props.chosenAspect ?
              renderOptions(props.chosenAspect)
              :
              ''
          }
        </List>
      </DialogContent>
      <DialogActions>
        <Button color='secondary' onClick={props.closeChildDialog}>
          Cancel
        </Button>
        <Button color='secondary' onClick={applyAspect}>
          Apply
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const RefinementsDialog: React.FC<RefinementsDialogProps> = (props) => {
  const [showChooseDialog, setShowChooseDialog] = React.useState(false)
  return (
    <div>
      {
        props.showDialog ?
          <Dialog fullWidth maxWidth='sm' open={props.showDialog && !props.isEditingAspect} onClose={props.closeDialog}>
            <List>
              {
                props.conditionInfo !== [] ?
                  <ListItem key='condition' button onClick={() => {
                    props.setChosenAspect('condition')
                    setShowChooseDialog(true)
                  }} component='div'>
                    <ListItemText primary='Condition' secondary={props.conditionInfo.length + ' options'} />
                  </ListItem>
                  :
                  ''
              }
              {
                props.aspects.map((aspect: EbayAspectInfo, index: number) =>
                  <ListItem key={index} button onClick={() => {
                    props.setChosenAspect(props.aspects[index])
                    setShowChooseDialog(true)
                  }}>
                    <ListItemText primary={aspect._name} secondary={aspect.valueHistogram.length + ' options'} />
                  </ListItem>
                )
              }
            </List>
          </Dialog>
          :
          ''
      }
      {
        (showChooseDialog && props.showDialog) || props.isEditingAspect ?
          <ChildDialog open={showChooseDialog || props.isEditingAspect} aspectFilters={props.aspectFilters}
                       closeParentDialog={props.closeDialog} setAspectFilters={props.setAspectFilters}
                       chosenAspect={props.chosenAspect!} conditionInfo={props.conditionInfo} closeChildDialog={() => {
            setShowChooseDialog(false)
            if (props.isEditingAspect) {
              props.setIsEditingAspect(false)
              props.closeDialog()
            }
            props.setChosenAspect(null)
          }} />
          :
          ''
      }
    </div>
  )
}

export default RefinementsDialog