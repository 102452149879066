import MainOverflowMenu from './MainOverflowMenu'
import React from 'react'
import { AppBar, Typography, Theme, Toolbar } from '@material-ui/core'
import { makeStyles, createStyles } from '@material-ui/styles'
import PreferencesForm from './PreferencesForm'
import FeedbackForm from './FeedbackForm'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      flex: 'none',
    },
    title: {},
    eFerretLogo: {
      height: '3em',
      paddingRight: '0.5em',
      marginLeft: '-1em'
    },
    beta: {
      paddingLeft: '0.7em',
      fontSize: '0.6em'
    }
  })
)

const HeaderAppBar: React.FC<{}> = (props) => {
  const classes = useStyles()
  const [showPreferencesMenu, setShowPreferencesMenu] = React.useState(false)
  const [showFeedbackMenu, setShowFeedbackMenu] = React.useState(false)
  return (
      <AppBar position='static' className={classes.header}>
        <Toolbar>
          <img src='/static/eferret_logo_play.png' alt='eFerret logo' className={classes.eFerretLogo} />
          <Typography variant='h6' className={classes.title}>
            eFerret
          </Typography>
          <Typography variant='button' className={classes.beta}>
            Beta
          </Typography>

          <MainOverflowMenu showPreferencesMenu={() => setShowPreferencesMenu(true)}
                            showFeedbackMenu={() => setShowFeedbackMenu(true)} />

        </Toolbar>
        <PreferencesForm open={showPreferencesMenu} handleClose={() => setShowPreferencesMenu(false)} />
        <FeedbackForm open={showFeedbackMenu} handleClose={() => setShowFeedbackMenu(false)} />
      </AppBar>
  )
}

export default HeaderAppBar