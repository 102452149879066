import React from 'react'
import { View } from 'react-native'
import Card from '@material-ui/core/Card'
import {
  Badge,
  CardActionArea,
  CardActions,
  CardMedia,
  createStyles,
  IconButton,
  makeStyles,
  Theme
} from '@material-ui/core'

import { useHistory } from 'react-router-dom'

import { eFerretQuery, QueryCardProps } from './interfaces'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import * as utilities from './utilities'
import { getQueryById } from './utilities'
import TimeListedOverlay from './TimeListedOverlay'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      width: 250
    },
    fixedHeight: {
      paddingBottom: '75%',
      position: 'relative'
    },
    titleOverlay: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      background:
        'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.5) 60%, rgba(0,0,0,0) 100%)',
      height: 30,
      padding: theme.spacing(1)
    },
    titleText: {
      color: '#fff',
      fontSize: '1.1em',
      paddingRight: theme.spacing(0.5)
    },
    rightAlignedButton: {
      marginLeft: 'auto'
    },
    newResultsBadge: {}
  })
)

const QueryCard: React.FC<QueryCardProps> = (props) => {
  const classes = useStyles()
  const history = useHistory()

  function handleEditQuery() {
    let queryIdToEdit = props.query.id

    history.push('/edit/' + queryIdToEdit)
    props.fillFormFieldsFromQuery(queryIdToEdit)
  }

  function handleDeleteQuery() {
    let queryIdToDelete = props.query.id
    let existingQueriesString = localStorage.getItem('queries')
    if (existingQueriesString === null) {
      console.log('Delete called, but query string is empty')
      return
    }
    let existingQueries: eFerretQuery[] = JSON.parse(existingQueriesString!)
    let newQueries = existingQueries.filter((query) => query.id !== queryIdToDelete)
    localStorage.setItem('queries', JSON.stringify(newQueries))
    props.setQueryData(newQueries)
    console.log('Deleted query with ID ' + queryIdToDelete)

    utilities.deleteQuery(queryIdToDelete).then((res) => {
      console.log('Query deleted!')
    })
  }

  function handleViewResults(qid: number) {
    props.setSelectedQuery(qid)
    let originalQuery = getQueryById(qid)
    if (originalQuery === null) {
      console.log('Got null / unknown query in handleViewResults')
      return
    }

    let existingQueriesString = localStorage.getItem('queries')
    if (existingQueriesString === null) {
      console.log('Attempted to view results, but no queries found in localStorage')
      return
    }

    let existingQueries: eFerretQuery[] = JSON.parse(existingQueriesString)
    if (originalQuery.results === undefined) {
      return
    }

    if (existingQueries.length === 0) return

    // Mark all results as seen
    existingQueries = existingQueries.map((query) => {
      if (query.id === qid && query.results !== undefined) {
        query.results = query.results.map((results) => {
          return {...results, seen: true}
        })
        return query
      }
      return query
    })
    props.setQueryData(existingQueries)
    localStorage.setItem('queries', JSON.stringify(existingQueries))
  }

  return (
    <View>
      <Card className={classes.card}
            elevation={props.selectedQuery !== null && props.query.id === props.selectedQuery ? 10 : 1}>
        <CardActionArea onClick={() => handleViewResults(props.query.id)}>
          {
            props.query !== null && props.query.results !== undefined && props.query.results !== null && props.query.results[0] !== undefined ?
              <CardMedia image={props.query.results![0].imageURL} className={classes.fixedHeight} />
              :
              <div className={classes.fixedHeight} />
          }
          <div className={classes.titleOverlay}>
            <Badge color='secondary' variant={'dot'} invisible={props.numNewResults === 0}
                   className={classes.newResultsBadge}>
              <span className={classes.titleText}>
                {
                  props.query.query !== undefined ?
                    props.query.query.keywords
                    :
                    '[Undefined]'
                }
              </span>
            </Badge>
          </div>
          {
            props.query.results !== undefined && props.query.results !== null && props.query.results[0] !== undefined ?
              <TimeListedOverlay bottom timeListed={props.query.results![0].listingInfo.startTime} />
              :
              ''
          }
        </CardActionArea>
        <CardActions>
          <IconButton onClick={handleEditQuery}>
            <EditIcon />
          </IconButton>
          <IconButton onClick={handleDeleteQuery}>
            <DeleteIcon />
          </IconButton>
        </CardActions>
      </Card>
    </View>
  )
}

export default QueryCard