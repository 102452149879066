import React from 'react'
import * as firebase from 'firebase/app'
import 'firebase/messaging'
import MainScreen from './MainScreen'
import { createStyles, makeStyles, Theme } from '@material-ui/core'
import axios from 'axios'
import * as utilities from './utilities'
import { eFerretQuery, eFerretResultsResponse } from './interfaces'

import { BrowserRouter as Router } from 'react-router-dom'

// Your web app's Firebase configuration
var firebaseConfig = {
  apiKey: 'AIzaSyAik6FsybSoC7iEmU-aOBZkt5XvECTMBA8',
  authDomain: 'eferret-179916.firebaseapp.com',
  databaseURL: 'https://eferret-179916.firebaseio.com',
  projectId: 'eferret-179916',
  storageBucket: 'eferret-179916.appspot.com',
  messagingSenderId: '1069040557100',
  appId: '1:1069040557100:web:e1b20a665a2b31cd'
}
firebase.initializeApp(firebaseConfig)

axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: '100%'
    }
  })
)

const App: React.FC<{}> = () => {
  const classes = useStyles()
  const queriesString = localStorage.getItem('queries')
  let data: eFerretQuery[] = []

  const isSupported = firebase.messaging.isSupported()
  if (isSupported) {
    const messaging = firebase.messaging()
    messaging.onMessage((payload) => {
      console.log('Message received while foregrounded...')
      console.log(payload.data as eFerretResultsResponse[])
      utilities.processNewResults(payload.data as eFerretResultsResponse[], setQueryData)
    })

  }

  if (queriesString !== null) {
    data = JSON.parse(queriesString)
  }
  const [queryData, setQueryData] = React.useState<eFerretQuery[]>(data)
  return (
    <div className={classes.root}>
      <Router>
        <MainScreen queryData={queryData} setQueryData={setQueryData} />
      </Router>
    </div>
  )
}

export default App
