import React from 'react'
import * as utilities from './utilities'
import { FormattedPriceProps } from './interfaces'
import { makeStyles, createStyles } from '@material-ui/styles'
import { Theme } from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    price: {
      fontSize: '1.5em',
      fontFamily: 'roboto, sans-serif',
      fontWeight: 500,
      marginTop: 5,
      paddingBottom: 5
    },
    priceInline: {
      display: 'inline',
      fontSize: '1.5em',
      fontFamily: 'roboto, sans-serif',
      fontWeight: 500,
      marginTop: 5,
      paddingBottom: 5
    },
    priceBig: {},
    priceSmall: {
      fontSize: '0.6em',
      position: 'relative',
      verticalAlign: 'baseline',
      top: '-0.4em'
    }
  })
)

const FormattedPrice: React.FC<FormattedPriceProps> = (props) => {
  const classes = useStyles()

  const formatPrice = (currency: string, amount: string | number) => {
    const formattedParts = utilities.getFormattedPrice(currency, amount)
    let output: JSX.Element[] = []
    for (let i = 0; i < formattedParts.length; i++) {
      if (formattedParts[i].type === 'decimal') {
        // Don't render decimals; fractions are separated by size formatting
      } else if (formattedParts[i].type === 'fraction' || formattedParts[i].type === 'currency') {
        output.push(<span key={i} className={classes.priceSmall}>{formattedParts[i].value}</span>)
      } else {
        output.push(<span key={i} className={classes.priceBig}>{formattedParts[i].value}</span>)
      }
    }
    return output
  }
  return (
    <div className={props.inline ? classes.priceInline : classes.price}>
      {
        props.amount === 0 || Number.parseFloat(props.amount as string) === 0.0 ?
          'Free'
          :
          formatPrice(props.currency, props.amount)
      }
    </div>
  )
}

export default FormattedPrice