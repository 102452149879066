import React from 'react'
import { EtsyListingSearchResult, EtsyResultCardProps, EtsyResultsProps } from './interfaces'
import { Card, CardActionArea, CardMedia, Typography, CardContent, Theme, Paper } from '@material-ui/core'
import FormattedPrice from './FormattedPrice'
import { makeStyles, createStyles } from '@material-ui/styles'
import ReactGA from 'react-ga'
import ItemSpecificsOverlay from './ItemSpecificsOverlay'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      width: '100%',
      marginBottom: 10
    },
    cardMedia: {
      width: '100%',
      paddingTop: '100%',
      position: 'relative'
    },
    cardActionArea: {
      height: '100%'
    },
    price: {
      fontSize: '1.25em',
      fontFamily: 'roboto, sans-serif',
      fontWeight: 500,
      marginTop: 5,
      paddingBottom: 5
    },
    priceBig: {},
    priceSmall: {
      fontSize: '0.6em',
      position: 'relative',
      verticalAlign: 'baseline',
      top: '-0.4em'
    },
    listingInfo: {
      fontSize: '0.9em',
      fontFamily: 'roboto, sans-serif',
      fontWeight: 300
    },
    etsyLogo: {
      position: 'absolute',
      bottom: 10,
      right: 10,
      width: 50
    },
    aspectNoteContainer: {
      position: 'absolute',
      display: 'grid',
      alignItems: 'right',
      bottom: 0,
      right: 0,
      padding: 5,
      fontSize: '0.8em'
    },
    aspectNoteVintage: {
      backgroundColor: '#a22',
      color: '#fff',
      marginTop: 5,
      marginLeft: 'auto',
      padding: 2,
      paddingLeft: 4,
      paddingRight: 4
    },
    aspectNoteHandmade: {
      backgroundColor: '#073',
      color: '#fff',
      marginTop: 5,
      marginLeft: 'auto',
      padding: 2,
      paddingLeft: 4,
      paddingRight: 4
    },
    aspectNoteMadeToOrder: {
      backgroundColor: '#047',
      color: '#fff',
      marginTop: 5,
      marginLeft: 'auto',
      padding: 2,
      paddingLeft: 4,
      paddingRight: 4
    }
  })
)

const EtsyResultCard: React.FC<EtsyResultCardProps> = (props) => {
  const classes = useStyles()
  const handleClick = () => {
    ReactGA.event({
      category: 'Conversion',
      action: 'Etsy clickthrough',
      label: props.result.listing_id.toString()
    })
  }

  return (
    <Card className={classes.card} elevation={1}>
      <CardActionArea onClick={handleClick} href={props.result.url} target='_blank' rel='noopener sponsored'
                      className={classes.cardActionArea}>
        <CardMedia className={classes.cardMedia}
                   image={props.result.MainImage ? props.result.MainImage!.url_570xN : ''}>
          <ItemSpecificsOverlay item={props.result} />
        </CardMedia>
        <CardContent>
          <Typography variant='body1'>{props.result.title}</Typography>
          <FormattedPrice currency={props.result.currency_code}
                          amount={props.result.price} />
        </CardContent>
        <img src='/static/etsy-logo.png' className={classes.etsyLogo} />
      </CardActionArea>
    </Card>
  )
}

const EtsyResults: React.FC<EtsyResultsProps> = (props) => {
  return (
    <div>
      {
        props.results ?
          props.results.map((result: EtsyListingSearchResult, index) => {
            return (
              <div key={index}>
                <EtsyResultCard result={result} />
              </div>
            )
          })
          :
          ''
      }
    </div>
  )
}

export default EtsyResults