import React from 'react'
import { eFerretSearchResult, EtsyListingSearchResult } from './interfaces'
import { makeStyles, createStyles } from '@material-ui/styles'
import { Theme, Paper, Typography } from '@material-ui/core'

interface ItemSpecificsOverlayProps {
  item: eFerretSearchResult | EtsyListingSearchResult
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    itemSpecificsContainer: {
      position: 'absolute',
      display: 'grid',
      alignItems: 'right',
      bottom: 0,
      right: 0,
      padding: 5
    },
    itemSpecificPaper: {
      marginTop: 3,
      marginLeft: 'auto',
      paddingLeft: 4,
      paddingRight: 4
    },
    text: {
      color: '#fff'
    }
  })
)

const ItemSpecificsOverlay: React.FC<ItemSpecificsOverlayProps> = (props) => {
  const classes = useStyles()

  interface itemSpecific {
    name: string
    color: string
  }

  let specifics: itemSpecific[] = []

  if ('listingInfo' in props.item) { // eBay item
    if (props.item.listingInfo.bestOfferEnabled === 'true') {
      specifics.push({
        name: 'Best offer',
        color: '#800'
      })
    }
    if (props.item.shippingInfo && props.item.shippingInfo!.shippingType === 'Free') {
      specifics.push({
        name: 'Free shipping',
        color: '#008'
      })
    }
    if (props.item.shippingInfo && props.item.shippingInfo!.expeditedShipping === 'true') {
      specifics.push({
        name: 'Expedited shipping available',
        color: '#260'
      })
    }
  } else if ('listing_id' in props.item) { // Etsy item
    if (props.item.tags && props.item.tags!.includes('handmade')) {
      specifics.push({
        name: 'Handmade',
        color: '#063'
      })
    }
    if (props.item.is_vintage) {
      specifics.push({
        name: 'Vintage',
        color: '#a22'
      })
    }
    if (props.item.when_made === 'made_to_order') {
      specifics.push({
        name: 'Made to order',
        color: '#047'
      })
    }
  }

  return (
    <div className={classes.itemSpecificsContainer}>
      {
        specifics.map((specific: itemSpecific, index) => {
          return (
            <Paper key={index} className={classes.itemSpecificPaper} style={{backgroundColor: specific.color}}>
              <Typography variant='caption' className={classes.text}>
                {specific.name}
              </Typography>
            </Paper>
          )
        })
      }
    </div>
  )
}

export default ItemSpecificsOverlay