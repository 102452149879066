import React from 'react'
import { EditQueryFormProps, FormFieldsProps } from './interfaces'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Fade,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Slide,
  Switch,
  TextField,
  Typography,
  useMediaQuery
} from '@material-ui/core'

import { createStyles, makeStyles, Theme, useTheme } from '@material-ui/core/styles'
import CategorySelector from './CategorySelector'
import { TransitionProps } from '@material-ui/core/transitions/transition'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 400,
      overflowX: 'hidden'
    },
    mobileRoot: {
      width: '94%'
    },
    paper: {
      padding: '4px',
      margin: '6px',
      backgroundColor: '#fff'
    },
    textField: {
      width: '100%'
    },
    formControl: {
      marginTop: '6px',
      marginBottom: '6px',
      maxWidth: '100%'
    },
    formControlFullWidth: {
      marginTop: '6px',
      marginBottom: '6px',
      width: '100%'
    },
    selectEmpty: {
      marginTop: 0,
      maxWidth: '100%',
      paddingRight: 0
    },
    smallSelectEmpty: {
      marginTop: 0,
      maxWidth: '100%',
      fontSize: '0.85rem',
      paddingRight: 0
    },
    fullWidth: {
      width: '100%'
    },
    topSectionHeader: {
      marginTop: '16px'
    },
    sectionHeader: {
      marginTop: '32px'
    },
    sectionPaper: {
      padding: '6px'
    },
    backgroundPaper: {
      backgroundColor: '#ccc'
    },
    currencySelect: {
      marginTop: '14px',
      width: '100%'
    },
    priceField: {
      marginTop: '4px',
      width: '100%'
    },
    button: {
      marginTop: '16px'
    },
    smallLabel: {
      fontSize: '0.85rem'
    },
    noMarginLabel: {
      marginLeft: 0
    },
    dialog: {},
    dialogTwoColumns: {
      position: 'absolute',
      left: 0,
      top: 0,
      maxWidth: '50%',
      width: '50%'
    },
    dialogTitle: {
      backgroundColor: '#4050b5',
      color: '#fff'
    },
    dialogContent: {
      width: 400
    },
    dialogContentMobile: {
      padding: theme.spacing(2)
    },
    highPrioritySwitch: {
      paddingTop: '1em'
    }
  })
)

const FormFields: React.FC<FormFieldsProps> = (props) => {
  const classes = useStyles()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'))
  const formProps = {...props.formProps}

  const handleChangeText = (name: keyof typeof formProps['textValues']) => (event: React.ChangeEvent<{ value: unknown }>) => {
    formProps.setTextValues({...formProps.textValues, [name]: event.target.value})
  }

  const handleChangeSwitch = (name: keyof typeof formProps['switchState']) => (event: React.ChangeEvent<HTMLInputElement>) => {
    formProps.setSwitchState({...formProps.switchState, [name]: event.target.checked})
    if (name === 'searchDescription') {
      formProps.getResultsPreview()
    }
  }

  const handleChangeSelect = (name: keyof typeof formProps['selectValues']) => (event: React.ChangeEvent<{ value: unknown }>) => {
    formProps.setSelectValues({...formProps.selectValues, [name]: event.target.value})
    if (name === 'siteId') {
      console.log('Site ID changed, resetting categories')
      formProps.setCategoryHierarchy([])
    }
    formProps.getResultsPreview()
  }

  return (
    <Grid container direction='column'>
      <Grid item className={isMobile ? classes.topSectionHeader : ''}>
        <Typography variant='overline'>
          Search Criteria
        </Typography>
        <Divider variant='fullWidth' />
      </Grid>
      <Grid item className={classes.fullWidth}>
        <TextField
          autoFocus
          id='keywords'
          label='Keywords'
          variant='standard'
          className={classes.textField}
          value={formProps.textValues.keywords}
          onChange={handleChangeText('keywords')}
          margin='normal'
        />
      </Grid>
      <Grid item container direction='row' justify='space-between' className={classes.fullWidth}>
        <Grid item>
          <FormControl className={classes.formControl}>
            <Select
              value={formProps.selectValues.auctionType}
              onChange={handleChangeSelect('auctionType')}
              displayEmpty
              name="auctionType"
              className={isMobile ? classes.smallSelectEmpty : classes.selectEmpty}
            >
              <MenuItem value={0}>Find Fixed Price items</MenuItem>
              <MenuItem value={1}>Find Auctions</MenuItem>
              <MenuItem value={2}>Find both</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item>
          <FormControlLabel
            className={isMobile ? classes.noMarginLabel : ''}
            classes={isMobile ? {label: classes.smallLabel} : {}}
            control={<Switch checked={formProps.switchState.searchDescription}
                             onChange={handleChangeSwitch('searchDescription')}
                             value="searchDescription" />}
            label="Search Description"
            labelPlacement='start'
          />
        </Grid>
      </Grid>
      <Grid item className={classes.sectionHeader}>
        <Typography variant='overline'>
          Price (Optional)
        </Typography>
        <Divider variant='fullWidth' />
      </Grid>
      <Grid item container spacing={1} justify='space-between' wrap='nowrap' className={classes.fullWidth}>
        <Grid item>
          <FormControl className={classes.formControl}>
            <Select
              value={formProps.selectValues.currency}
              onChange={handleChangeSelect('currency')}
              displayEmpty
              name="auctionType"
              className={classes.currencySelect}
            >
              <MenuItem value='USD'>USD</MenuItem>
              <MenuItem value='AUD'>AUD</MenuItem>
              <MenuItem value='CAD'>CAD</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item>
          <TextField
            id='minPrice'
            label='Min. Price'
            variant='standard'
            className={classes.priceField}
            value={formProps.textValues.minPrice}
            onChange={handleChangeText('minPrice')}
            margin='normal'
          />
        </Grid>
        <Grid item>
          <TextField
            id='maxPrice'
            label='Max. Price'
            variant='standard'
            className={classes.priceField}
            value={formProps.textValues.maxPrice}
            onChange={handleChangeText('maxPrice')}
            margin='normal'
          />
        </Grid>
      </Grid>
      <Grid item className={classes.sectionHeader}>
        <Typography variant='overline'>
          Category & Refinements
        </Typography>
        <Divider variant='fullWidth' />
      </Grid>
      <Grid item className={classes.fullWidth}>
        <CategorySelector categoryHierarchy={formProps.categoryHierarchy}
                          setCategoryHierarchy={formProps.setCategoryHierarchy} aspectFilters={formProps.aspectFilters}
                          setAspectFilters={formProps.setAspectFilters} siteId={formProps.selectValues.siteId} />
      </Grid>

      {
        props.showAdvancedOptions ?
          <div>
            <Grid item className={classes.sectionHeader}>
              <Typography variant='overline'>
                Advanced Options
              </Typography>
              <Divider variant='fullWidth' />
            </Grid>
            <Grid item container className={classes.fullWidth} justify='space-between'>
              <Grid item>
                <FormControl className={classes.formControl}>
                  <InputLabel htmlFor='siteId'>eBay site</InputLabel>
                  <Select
                    value={formProps.selectValues.siteId}
                    onChange={handleChangeSelect('siteId')}
                    displayEmpty
                    name='siteId'
                    className={classes.fullWidth}
                  >
                    <MenuItem value='0'>eBay United States</MenuItem>
                    <MenuItem value='100'>eBay Motors</MenuItem>
                    <MenuItem value='2'>eBay Canada (English)</MenuItem>
                    <MenuItem value='210'>eBay Canada (French)</MenuItem>
                    <MenuItem value='3'>eBay UK</MenuItem>
                    <MenuItem value='15'>eBay Australia</MenuItem>
                    <MenuItem value='16'>eBay Austria</MenuItem>
                    <MenuItem value='23'>eBay Belgium (French)</MenuItem>
                    <MenuItem value='123'>eBay Belgium (Dutch)</MenuItem>
                    <MenuItem value='71'>eBay France</MenuItem>
                    <MenuItem value='77'>eBay Germany</MenuItem>
                    <MenuItem value='101'>eBay Italy</MenuItem>
                    <MenuItem value='146'>eBay Netherlands</MenuItem>
                    <MenuItem value='186'>eBay Spain</MenuItem>
                    <MenuItem value='193'>eBay Switzerland</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item>
                <FormControlLabel
                  className={classes.highPrioritySwitch}
                  classes={isMobile ? {label: classes.smallLabel} : {}}
                  control={<Switch checked={formProps.switchState.highPriority}
                                   onChange={handleChangeSwitch('highPriority')}
                                   value="highPriority" />}
                  label="High Priority"
                  labelPlacement='start'
                />
              </Grid>
            </Grid>
          </div>
          :
          ''
      }
      <Grid item>
        {formProps.formError}
      </Grid>
    </Grid>
  )
}

const Transition = React.forwardRef<unknown, TransitionProps>(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const FadeTransition = React.forwardRef<unknown, TransitionProps>(function Transition(props, ref) {
  return <Fade in ref={ref} {...props} />
})

const EditQueryForm: React.FC<EditQueryFormProps> = (props) => {
  const classes = useStyles()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'))
  const [showAdvancedOptions, setShowAdvancedOptions] = React.useState(false)
  if (props.editingQuery !== null && props.formProps.textValues.keywords === '') {
    props.fillFormFieldsFromQuery!(props.editingQuery)
  }

  function handleShowAdvancedOptionsChange(event: React.ChangeEvent<HTMLInputElement>) {
    setShowAdvancedOptions(event.target.checked)
  }

  return (
    <div className={isMobile ? classes.mobileRoot : classes.root}>
      <Dialog fullScreen={props.fullscreen}
              TransitionComponent={props.fullscreen ? Transition : FadeTransition} scroll='paper'
              open={props.editingQuery !== null}
              BackdropProps={props.showTwoColumns ? {style: {width: '50%'}} : undefined}
              className={props.showTwoColumns ? classes.dialogTwoColumns : classes.dialog}>
        <DialogTitle className={props.fullscreen ? classes.dialogTitle : ''} id='edit-query-dialog-title'>
          <Grid container direction='row' justify='space-between' wrap='nowrap'>
            <Grid item>New Search</Grid>
            <Grid item>
              <FormControlLabel
                control={<Switch checked={showAdvancedOptions} onChange={handleShowAdvancedOptionsChange} />}
                label="Show Advanced Options"
                labelPlacement='start'
              />
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent className={props.fullscreen ? classes.dialogContentMobile : classes.dialogContent}>
          <Grid container alignItems='flex-start' justify='center'>
            <FormFields formProps={props.formProps} showAdvancedOptions={showAdvancedOptions} />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.handleClose} color='secondary'>
            Cancel
          </Button>
          <Button onClick={() => props.submitQuery(false)} color='secondary'>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default EditQueryForm
