import React from 'react'
import { EbayCategoryInfo, EbayGenericCategoryInfo, EbayTopLevelCategoryInfo } from './interfaces'
import {
  createStyles,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  Theme
} from '@material-ui/core'
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp'

interface CategoryDropdownProps {
  categoryInfo: EbayGenericCategoryInfo[]
  disabled: boolean
  goUp: () => void
  getCategories: (siteId: string, categoryId: string) => void
  addToHierarchy: (category: EbayGenericCategoryInfo) => void
  siteId: string
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControlFullWidth: {
      marginTop: '6px',
      marginBottom: '6px',
      width: '100%'
    },
    categorySelect: {
      marginTop: '4px',
      width: '100%'
    },
    fullWidth: {
      width: '100%'
    },
    upButton: {
      marginTop: '14px'
    }
  })
)

const CategoryDropdown: React.FC<CategoryDropdownProps> = (props) => {
  const classes = useStyles()
  const [category, setCategory] = React.useState<unknown>('')

  function getCategory(id: string) {
    let numberOfCategories = props.categoryInfo.length
    for (let i = 0; i < numberOfCategories; i++) {
      if (props.categoryInfo[i].id === id) {
        return props.categoryInfo[i]
      }
    }
    return {id: '', name: ''}
  }

  function handleChange(event: React.ChangeEvent<{ value: unknown }>) {
    if (typeof event.target.value === 'string') {
      console.log('Setting category to ' + event.target.value)
      props.addToHierarchy(getCategory(event.target.value))
      props.getCategories(props.siteId, event.target.value)
    }
  }

  function categoryOptions(categories: EbayGenericCategoryInfo[]) {
    return (
      categories.map((category: EbayGenericCategoryInfo) =>
        <MenuItem key={category.id} value={category.id}>{category.name}</MenuItem>
      )
    )
  }

  return (
    <div>
      <Grid item container justify='space-between'>
        <Grid item xs={10}>
          <FormControl className={classes.formControlFullWidth} disabled={props.disabled}>
            <InputLabel htmlFor='category'>Choose a category (Optional)</InputLabel>
            <Select
              value={category}
              onChange={handleChange}
              displayEmpty
              name="category"
              className={classes.categorySelect}>

              {
                props.categoryInfo !== undefined ?
                  categoryOptions(props.categoryInfo)
                  :
                  ''
              }

            </Select>
          </FormControl>
        </Grid>
        <Grid item>
          <IconButton
            color='primary'
            onClick={props.goUp}
            className={classes.upButton}
            component='span'>
            <ArrowDropUpIcon />
          </IconButton>
        </Grid>
      </Grid>
    </div>
  )
}

export default CategoryDropdown