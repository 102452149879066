import React from 'react'
import { Menu, IconButton, MenuItem, createStyles, makeStyles, Theme } from '@material-ui/core'
import MoreVertIcon from '@material-ui/icons/MoreVert'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    rightAligned: {
      marginLeft: 'auto',
      marginRight: '-1em'
    },
    whiteIcon: {
      color: '#fff'
    }
  })
)

interface MainOverflowMenuProps {
  showPreferencesMenu: () => void
  showFeedbackMenu: () => void
}

const MainOverflowMenu: React.FC<MainOverflowMenuProps> = (props) => {
  const classes = useStyles()
  const [menuAnchor, setMenuAnchor] = React.useState<null | HTMLElement>(null)

  function handleClose() {
    setMenuAnchor(null)
  }

  function handlePreferencesClick() {
    handleClose()
    props.showPreferencesMenu()
  }

  function handleFeedbackClick() {
    handleClose()
    props.showFeedbackMenu()
  }

  return (
    <div className={classes.rightAligned}>
      <IconButton onClick={(e) => setMenuAnchor(e.currentTarget)} aria-label='more actions'>
        <MoreVertIcon className={classes.whiteIcon} />
      </IconButton>
      <Menu
        anchorEl={menuAnchor}
        keepMounted
        open={Boolean(menuAnchor)}
        onClose={handleClose}>

        <MenuItem onClick={handlePreferencesClick}>Preferences</MenuItem>
        <MenuItem onClick={handleFeedbackClick}>Complain to the developer</MenuItem>
      </Menu>
    </div>
  )
}

export default MainOverflowMenu