import React from 'react'
import { EbayGenericCategoryInfo } from './interfaces'

interface HeirarchyViewProps {
  hierarchy: EbayGenericCategoryInfo[]
}

const HierarchyView: React.FC<HeirarchyViewProps> = (props) => {

  function spaces(numSpaces: number, numItems: number) {
    let space: string = ''
    for (let i = 0; i < numSpaces; i++) {
      space += '\xa0\xa0\xa0\xa0'
    }
    if (numSpaces === numItems - 1) {
      space += '➢\xa0'
    } else {
      space += '\xa0\xa0\xa0\xa0'
    }
    return space
  }

  return (
    <div>
      {
        props.hierarchy.map((hierarchy: EbayGenericCategoryInfo, index: number) =>
          <div key={index}>
            {
              spaces(index, props.hierarchy.length) + hierarchy.name
            }
          </div>
        )
      }
    </div>
  )
}

export default HierarchyView