import React from 'react'
import { eFerretSearchResult } from './interfaces'
import { Text } from 'react-native'
import ResultGridCard from './ResultGridCard'
import {
  BottomNavigation,
  BottomNavigationAction,
  createStyles,
  Grid,
  GridList,
  makeStyles,
  Theme
} from '@material-ui/core'
import StackGrid from 'react-stack-grid'
import ViewDashboardVariantIcon from 'mdi-react/ViewDashboardVariantIcon'
import ViewGridIcon from 'mdi-react/ViewGridIcon'
import InteractiveSearchResults from './InteractiveSearchResults'

interface ResultsViewProps {
  results: eFerretSearchResult[] | null
  twoColumns: boolean
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      width: '100%',
      height: '100%',
      maxHeight: '100%',
      backgroundColor: '#eee'
    },
    mainWindow: {
      flexGrow: 1,
      width: '100%',
      maxHeight: 'calc(100% - 56px)',
      padding: theme.spacing(1),
      overflowY: 'auto',
      overflowX: 'hidden'
    },
    bottomNavigation: {
      width: '100%',
      height: '56px'
    },
    listingTitle: {
      color: '#fff',
      fontSize: '0.9em'
    },
    titleBar: {
      background:
        'linear-gradient(to top, rgba(0,0,0,0.8) 0%, rgba(0,0,0,0.5) 70%, rgba(0,0,0,0) 100%)',
      borderBottomLeftRadius: '4px',
      borderBottomRightRadius: '4px'
    }
  })
)

const ResultsView: React.FC<ResultsViewProps> = (props) => {
  const classes = useStyles()
  const [viewMode, setViewMode] = React.useState('cards')
  const [sharePopperAnchor, setSharePopperAnchor] = React.useState<null | HTMLElement>(null)
  const [selectedResult, setSelectedResult] = React.useState<null | eFerretSearchResult>(null)

  function handleShareItem(event: React.MouseEvent<HTMLElement>, item: eFerretSearchResult) {
    setSelectedResult(item)
    setSharePopperAnchor(sharePopperAnchor ? null : event.currentTarget)
  }

  function handleChangeViewMode(event: React.ChangeEvent<{}>, newValue: string) {
    setViewMode(newValue)
  }

  return (
    <Grid container className={classes.root} direction='column' justify='space-between'>
      <Grid item className={classes.mainWindow}>
        {viewMode == 'stackgrid' ?
          <StackGrid columnWidth={props.twoColumns ? '33.33%' : '50%'} monitorImagesLoaded>
            {
              props.results !== null && props.results !== undefined ?
                props.results.map((result, index) =>
                  <div key={index.toString() + result.itemId}>
                    <ResultGridCard result={result} />
                  </div>
                )
                :
                <Text>No results yet.</Text>
            }
          </StackGrid>
          :
          <GridList cellHeight={250}>
            {
              props.results !== null && props.results !== undefined ?
                <InteractiveSearchResults halfWidth={props.twoColumns} results={props.results} />
                :
                <Text>No results yet.</Text>
            }
          </GridList>
        }
      </Grid>
      <Grid item className={classes.bottomNavigation}>
        <BottomNavigation value={viewMode} onChange={handleChangeViewMode}>
          <BottomNavigationAction value="cards" icon={<ViewGridIcon />} />
          <BottomNavigationAction value="stackgrid" icon={<ViewDashboardVariantIcon />} />
        </BottomNavigation>
      </Grid>
    </Grid>
  )
}

export default ResultsView