import React from 'react'
import { EbayAspectFilter, EbayAspectInfo, EbayConditionInfo } from './interfaces'
import { Chip, createStyles, makeStyles, Theme } from '@material-ui/core'

interface AspectFilterChipsProps {
  aspectFilters: EbayAspectFilter[]
  setAspectFilters: (newAspectFilters: EbayAspectFilter[]) => void
  conditionInfo: EbayConditionInfo[]
  aspects: EbayAspectInfo[]
  setChosenAspect: (aspect: null | EbayAspectInfo | 'condition') => void
  setShowDialog: (show: boolean) => void
  editChosenAspect: () => void
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    chip: {
      margin: '3px'
    }
  })
)

const AspectFilterChips: React.FC<AspectFilterChipsProps> = (props) => {

  const makeLabel = (aspectFilter: EbayAspectFilter) => {
    let baseLabel = ''
    let isCondition = aspectFilter.aspectName === 'condition'
    let condition = aspectFilter.aspectValueName as EbayConditionInfo['condition'][]
    if (isCondition) {
      baseLabel = 'Condition: '
    } else {
      baseLabel = aspectFilter.aspectName + ': '
    }
    if (isCondition) {
      baseLabel += condition[0].conditionDisplayName
    } else {
      baseLabel += aspectFilter.aspectValueName[0]
    }
    if (aspectFilter.aspectValueName.length > 1) {
      baseLabel += ' & ' + (aspectFilter.aspectValueName.length - 1).toString() + ' more...'
    }
    return baseLabel
  }

  const deleteAspect = (aspect: EbayAspectFilter) => {
    let newAspectFilters = Array.from(props.aspectFilters)
    newAspectFilters = newAspectFilters.filter((aspectFilter) => aspectFilter.aspectName !== aspect.aspectName)
    props.setAspectFilters(newAspectFilters)
  }

  const editAspect = (aspect: EbayAspectFilter) => {
    let propAspect: null | EbayAspectInfo
    if (aspect.aspectName === 'condition') {
      props.setChosenAspect('condition')
    } else {
      propAspect = props.aspects.filter((aspectInfo) => aspectInfo._name === aspect.aspectName)[0]
      props.setChosenAspect(propAspect)
    }
    props.editChosenAspect()
  }

  const classes = useStyles()

  return (
    <div>
      {
        props.aspectFilters.map((aspect, index) =>
          <Chip
            key={index}
            className={classes.chip}
            label={makeLabel(aspect)}
            color='primary'
            onClick={() => editAspect(aspect)}
            onDelete={() => deleteAspect(aspect)} />
        )
      }
    </div>
  )
}

export default AspectFilterChips