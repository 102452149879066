import React from 'react'
import { EbayAspectFilter, EbayAspectInfo, EbayConditionInfo } from './interfaces'
import RefinementsButton from './RefinementsButton'
import RefinementsDialog from './RefinementsDialog'
import AspectFilterChips from './AspectFilterChips'
import { View } from 'react-native'

interface AspectViewProps {
  aspects: EbayAspectInfo[]
  conditionInfo: EbayConditionInfo[]
  aspectFilters: EbayAspectFilter[]
  setAspectFilters: (filters: EbayAspectFilter[]) => void
  chosenAspect: null | EbayAspectInfo | 'condition'
  setChosenAspect: (aspect: null | EbayAspectInfo | 'condition') => void
}

const AspectView: React.FC<AspectViewProps> = (props) => {
  const [showDialog, setShowDialog] = React.useState(false)
  const [isEditingAspect, setIsEditingAspect] = React.useState(false)

  const editChosenAspect = () => {
    setIsEditingAspect(true)
    setShowDialog(true)
  }

  return (
    <View>
      {
        props.aspects.length > 0 ?
          <View>
            <RefinementsDialog chosenAspect={props.chosenAspect} setChosenAspect={props.setChosenAspect}
                               aspects={props.aspects} conditionInfo={props.conditionInfo}
                               aspectFilters={props.aspectFilters}
                               setAspectFilters={props.setAspectFilters} closeDialog={() => setShowDialog(false)}
                               showDialog={showDialog} isEditingAspect={isEditingAspect}
                               setIsEditingAspect={setIsEditingAspect} />
            <RefinementsButton onClick={() => setShowDialog(true)} />
          </View>
          :
          []
      }
      <AspectFilterChips aspects={props.aspects} setChosenAspect={props.setChosenAspect} setShowDialog={setShowDialog}
                         aspectFilters={props.aspectFilters} conditionInfo={props.conditionInfo} setAspectFilters={props.setAspectFilters}
                         editChosenAspect={editChosenAspect} />
    </View>
  )
}

export default AspectView