import {
  authorizableHeaders, eBayQuery,
  eFerretApiRequest,
  eFerretQuery,
  eFerretResultsResponse,
  eFerretSearchResult, eFerretSubmitQueryRequest, EtsyFindAllListingsActiveRequest, itemFilter
} from './interfaces'
import axios, { AxiosResponse } from 'axios'
import hmacSHA256 from 'crypto-js/hmac-sha256'
import Base64 from 'crypto-js/enc-base64'

class authorizedRequest {
  public request: string
  public headers: authorizableHeaders

  constructor(headers: authorizableHeaders, request: string) {
    this.request = request
    this.headers = headers
  }

  getData() {
    return {
      headers: this.headers,
      request: {request: this.request}
    }
  }
}

export function getFormattedPrice(currency: string, price: string | number) {
  // Source: https://stackoverflow.com/questions/673905/best-way-to-determine-users-locale-within-browser#674570
  const getLang = () => navigator.language || (navigator.languages || ['en']) [0]
  let numericPrice = price
  if (typeof numericPrice === 'string') {
    numericPrice = Number.parseFloat(numericPrice)
  }
  return new Intl.NumberFormat(getLang(), {style: 'currency', currency: currency.toUpperCase()})
    .formatToParts(price as number)
}

export function processNewResults(newResults: eFerretResultsResponse[], setQueryData?: (queries: eFerretQuery[]) => void) {
  console.log('Processing new results...')
  let oldQueriesString = localStorage.getItem('queries')
  let oldQueriesCopy: eFerretQuery[] = []
  if (oldQueriesString !== null) {
    oldQueriesCopy = JSON.parse(oldQueriesString)
  }
  let numResultsObjects: number
  if (Array.isArray(newResults)) {
    numResultsObjects = newResults.length
  } else {
    numResultsObjects = 1
  }
  for (let i = 0; i < numResultsObjects; i++) {
    console.log('Processing results for query #', i + 1)
    let results: eFerretResultsResponse
    if (Array.isArray(newResults)) {
      results = newResults[i]
    } else {
      results = newResults
    }
    let newItems: eFerretSearchResult[]
    if (results.results === undefined) {
      if (typeof results.new_results! === 'string') {
        newItems = JSON.parse(results.new_results) as eFerretSearchResult[]
        console.log('New results after parsing:')
        console.log(newItems)
      } else {
        newItems = results.new_results!
        console.log('New results after parsing:')
        console.log(newItems)
      }
    } else {
      if (typeof results.results! === 'string') {
        newItems = JSON.parse(results.results) as eFerretSearchResult[]
        console.log('New results after parsing:')
        console.log(newItems)
      } else {
        newItems = results.results!
        console.log('New results after parsing:')
        console.log(newItems)
      }
    }
    let existingQuery = getQueryById(results.qid)
    let queryCopy = JSON.parse(JSON.stringify(existingQuery))
    if (existingQuery === null) {
      console.log('Received results for nonexistant query, ID ' + results.qid.toString())
      deleteQuery(results.qid).then((res) => {
        console.log('Deleted nonexistant query!')
      })
    } else {
      if (queryCopy.results === undefined) {
        queryCopy.results = newItems.sort(function (a: eFerretSearchResult, b: eFerretSearchResult) {
          return b.listingInfo.startTime.localeCompare(a.listingInfo.startTime)
        })
      } else {
        queryCopy.results = queryCopy.results.concat(newItems) as eFerretSearchResult[]
        queryCopy.results = queryCopy.results.sort(function (a: eFerretSearchResult, b: eFerretSearchResult) {
          return b.listingInfo.startTime.localeCompare(a.listingInfo.startTime)
        })
      }
      oldQueriesCopy = oldQueriesCopy.filter((query) => query.id != results.qid)
      oldQueriesCopy.push(queryCopy)
    }
  }

  localStorage.setItem('queries', JSON.stringify(oldQueriesCopy))

  if (setQueryData !== undefined) {
    if (newResults.length > 0) {
      // Force re-render
      let queriesString = localStorage.getItem('queries')
      let queries: eFerretQuery[] = []
      if (queriesString !== null) {
        queries = JSON.parse(queriesString)
      }
      setQueryData(queries)
    }
  }
}

export function printTimeAgo(time: string) {
  let date = Date.parse(time)
  let now = Date.now()
  let delta = now - date
  let seconds = delta / 1000
  if (seconds < 200) {
    return 'Just now'
  } else {
    let minutes = seconds / 60
    if (minutes < 60) {
      return minutes.toFixed(0) + 'm'
    } else {
      let hours = minutes / 60
      if (hours < 24) {
        return hours.toFixed(0) + 'h'
      } else {
        let days = hours / 24
        return days.toFixed(0) + ' days'
      }
    }
  }
}

export function printPriceString(price: number, currency: string) {
  switch (currency) {
    case 'USD':
    case 'HKD':
    case 'MXN':
      return '$' + price.toFixed(2)
    case 'CAD':
      return '$' + price.toFixed(2) + ' CAD'
    case 'EUR':
      return '€' + price.toFixed(2)
    case 'CNY':
    case 'JPY':
      return '¥' + price.toFixed(2)
    case 'PHP':
      return '₱' + price.toFixed(2)
    default:
      return price.toFixed(2) + ' ' + currency
  }
}

export async function loginAsGuest() {
  // Log in as guest
  console.log('Logging in as guest')
  let timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
  await axios.post('/auth/',
    {
      timeZone: timeZone,
      type: 'guest',
      platform: 'web'
    })
    .then((res: AxiosResponse) => {
      console.log(res)
      if (res.data.status !== 'ok') {
        throw new Error('Error logging in')
      } else {
        console.log('Saving uid: ' + res.data.uid)
        localStorage.setItem('uid', res.data.uid)
        localStorage.setItem('clientSecret', res.data.client_secret)
        console.log('Got data:')
        console.log(res.data)
        return (res.data)
      }
    })
}

export async function submitEtsySearch(query: EtsyFindAllListingsActiveRequest) {
  return await axios.post('/etsy-search/', query)
}

export async function submitSearch(keywords: string, searchDescription: boolean, listingType: number,
                                   siteId: string, categoryId?: string, itemFilters?: itemFilter[],
                                   minPrice?: string, maxPrice?: string, currency?: string, preview?: boolean) {

  let uid = localStorage.getItem('uid')
  let postalCode = localStorage.getItem('postalCode')
  console.log('Running search: ' + keywords)

  if (!itemFilters) itemFilters = []
  if (minPrice) {
    itemFilters.push({
      name: 'MinPrice',
      value: minPrice,
      paramName: 'Currency',
      paramValue: currency!
    })
  }

  if (maxPrice) {
    itemFilters.push({
      name: 'MaxPrice',
      value: maxPrice,
      paramName: 'Currency',
      paramValue: currency!
    })
  }

  let query: eBayQuery = {
    keywords: keywords,
    descriptionSearch: searchDescription,
    listingType: listingType,
    itemFilter: itemFilters
  }

  if (postalCode && postalCode !== '') {
    query.buyerPostalCode = postalCode!
  }

  if (categoryId) {
    query.categoryId = categoryId
  }

  let request: eFerretSubmitQueryRequest = {
    requestType: 'submitQuery',
    highPriority: false,
    siteId: siteId,
    query: query,
    uid: uid!,
    preview: preview as boolean
  }

  let authorizedRequest = toFlatAuthJsonRequest(request)
  console.log('authorized request:')
  console.log(authorizedRequest)
  const endpoint = '/api/'

  return await axios.post(endpoint, authorizedRequest.request, {headers: authorizedRequest.headers}).then((res) => {
    return res
  })
}

export async function makeAuthorizedApiRequest(request: eFerretApiRequest): Promise<AxiosResponse> {
  let authorizedRequest = toFlatAuthJsonRequest(request)
  console.log('Making ' + request.requestType + ' request...')
  const endpoint = '/api/'

  return await axios.post(endpoint, authorizedRequest.request, {headers: authorizedRequest.headers})
}

async function makeRefreshTokenRequest(request: any): Promise<AxiosResponse> {
  let authorizedRequest = toFlatAuthJsonRequest(request)
  console.log('Making ' + request.requestType + ' request...')
  const endpoint = 'localhost:8000/refresh/'

  return await axios.post(endpoint, authorizedRequest.request, {headers: authorizedRequest.headers})
}

export async function deleteQuery(qid: number) {
  let request: eFerretApiRequest = {
    requestType: 'deleteQuery',
    qid: qid
  }

  return makeAuthorizedApiRequest(request)
}

export async function sendFeedback(feedback: string) {
  let request: eFerretApiRequest = {
    requestType: 'sendFeedback',
    feedback: feedback
  }
  return makeAuthorizedApiRequest(request)
}

export async function getCategories(siteId: string, categoryId: string): Promise<AxiosResponse> {
  let request: eFerretApiRequest = {
    requestType: 'getCategories',
    siteId: siteId,
    categoryId: categoryId,
    timestamp: Date.now()
  }

  return await makeAuthorizedApiRequest(request)
}

export async function refreshToken(token: string) {
  let uid = localStorage.getItem('uid')
  if (uid === null) {
    throw new Error('No uid found, cannot make authorized API request')
  }
  let request = {
    firebaseToken: token,
    userId: uid
  }
  return await makeRefreshTokenRequest(request)
}

export function getNumberOfNewResultsForQuery(qid: number) {
  console.log('Tallying new results...')
  let query = getQueryById(qid)
  if (query === null || query!.results === undefined) {
    return 0
  }
  let unseenTally: number[] = query!.results.map((result) => {
    return result.seen !== undefined && result.seen ? 0 : 1
  })
  let newResults = unseenTally.reduce((sum, tally) => sum + tally, 0)
  console.log('Found ' + newResults.toString() + ' new results')
  return newResults
}

export function getQueryById(id: number | string) {
  if (typeof id === 'string') {
    id = Number.parseInt(id)
  }
  let queriesString = localStorage.getItem('queries')
  if (queriesString === null) {
    console.log('No queries string found.')
    return null
  }
  let queries: eFerretQuery[] = JSON.parse(queriesString!)
  let matchingQueries: eFerretQuery[] = queries.filter((query) => query.id === id)
  if (matchingQueries.length > 1) {
    console.log('ERROR: Multiple queries matching ID ' + id.toString())
    return matchingQueries[0]
  }
  if (matchingQueries.length === 0) {
    console.log('getQueryById: No queries found matching ID', id)
    return null
  }
  return matchingQueries[0]
}

export function toFlatAuthJsonRequest(request: eFerretApiRequest) {
  let uid = localStorage.getItem('uid')
  if (uid === null) {
    throw new Error('No uid found, cannot make authorized API request')
  }
  request.uid = uid!
  request.timestamp = Date.now()
  let requestString = JSON.stringify(request)
  let clientSecret = localStorage.getItem('clientSecret')

  let headers = {
    uid: uid,
    hmac: Base64.stringify(hmacSHA256(requestString, clientSecret))
  }
  return new authorizedRequest(headers, requestString).getData()
}