import React, { BaseSyntheticEvent, useCallback, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import {
  Grid,
  Theme,
  InputBase,
  Paper,
  IconButton,
  Divider,
  Typography,
  MenuItem,
  Select,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Fab,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Snackbar,
  InputLabel,
  FormControl
} from '@material-ui/core'
import { makeStyles, createStyles } from '@material-ui/styles'
import CloseIcon from '@material-ui/icons/Close'
import SearchIcon from '@material-ui/icons/Search'
import * as utilities from './utilities'
import InteractiveSearchResults from './InteractiveSearchResults'
import {
  eBayCategoryHistogram,
  eFerretQuery,
  eFerretSearchResult,
  EtsyListingSearchResult,
  itemFilter
} from './interfaces'
import PriceSlider from './PriceSlider'
import InteractiveCategorySelector from './InteractiveCategorySelector'
import NotificationsIcon from '@material-ui/icons/Notifications'
import EtsyResults from './EtsyResults'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    searchHeaderPaper: {
      height: '100%',
      borderRadius: 0
    },
    mainWindow: {
      backgroundColor: '#eee',
      height: '100%',
      display: 'flex',
      flex: 1,
      flexGrow: 1
    },
    sidebar: {
      minHeight: '100%',
      borderRadius: 0,
      padding: 15
    },
    searchBarPaper: {
      width: '75%',
      margin: 15,
      marginLeft: 'auto',
      marginRight: 'auto',
      padding: '2px 4px',
      borderRadius: 15
    },
    searchBarForm: {
      flex: 1,
      display: 'flex'
    },
    searchBar: {
      flex: 1,
      marginLeft: 15,
      fontSize: '1.2em'
    },
    resultsView: {},
    resultsCards: {
      padding: 10,
      paddingTop: 0
    },
    searchSubmitButton: {
      maxHeight: 48,
      marginTop: 'auto',
      marginBottom: 'auto'
    },
    fullWidthGrid: {
      flex: 1
    },
    auctionTypeSelect: {
      marginLeft: 10
    },
    expansionPanel: {
      marginTop: 10
    },
    expansionPanelDetails: {
      padding: 0
    },
    fab: {
      position: 'fixed',
      bottom: 30,
      left: 0,
      right: 0,
      marginLeft: 'auto',
      marginRight: 'auto',
      width: 'max-content'
    },
    rightNowLogo: {
      marginLeft: 'auto',
      marginRight: 'auto',
      maxWidth: 125,
      marginBottom: 10
    },
    closeSnackbarButton: {
      padding: 5
    }
  })
)

interface InteractiveSearchProps {
  setQueryData: (queries: eFerretQuery[]) => void
  handleClose: () => void
  showTwoColumns: boolean
}

const InteractiveSearch: React.FC<InteractiveSearchProps> = (props) => {
  const [loggingIn, setLoggingIn] = React.useState(false)
  const [searchResults, setSearchResults] = React.useState<eFerretSearchResult[] | null>(null)
  const [etsyResults, setEtsyResults] = React.useState<EtsyListingSearchResult[] | undefined>(undefined)
  const [categoryHistogram, setCategoryHistogram] = React.useState<eBayCategoryHistogram[] | null>(null)
  const [shouldDoSearch, setShouldDoSearch] = React.useState(true)
  const [observedPriceMinMax, setObservedPriceMinMax] = React.useState([0, 100])
  const [priceMinMax, setPriceMinMax] = React.useState(observedPriceMinMax)
  const [hasManuallySetPrice, setHasManuallySetPrice] = React.useState(false)
  const [auctionType, setAuctionType] = React.useState(2)
  const [selectedCategory, setSelectedCategory] = React.useState<string | undefined>(undefined)
  const [errorDialogText, setErrorDialogText] = React.useState('')
  const [showErrorDialog, setShowErrorDialog] = React.useState(false)
  const [showSnackbar, setShowSnackbar] = React.useState(false)
  const [snackbarMessage, setSnackbarMessage] = React.useState('')

  let preferredCurrency = localStorage.getItem('preferredCurrency')
  if (!preferredCurrency) preferredCurrency = 'USD'

  const [observedCurrency, setObservedCurrency] = React.useState(preferredCurrency)

  let preferredSite = localStorage.getItem('preferredSite')
  if (!preferredSite) preferredSite = '0'

  useEffect(() => {
    console.log('Doing search because auctionType or selectedCategory updated...')
    setShouldDoSearch(true)
  }, [auctionType, selectedCategory])

  useEffect(() => {
    if (hasManuallySetPrice) {
      console.log('Doing search because price settings updated...')
      setShouldDoSearch(true)
    }
  }, [priceMinMax])

  useEffect(() => {
    if (shouldDoSearch) {
      doSearch(query)
    }
  }, [shouldDoSearch])

  const classes = useStyles()
  const history = useHistory()
  const query = history.location.pathname.split('/').pop()
  const inputRef: React.RefObject<HTMLInputElement> = React.createRef()
  let uid = localStorage.getItem('uid')

  function handleCloseSnackbar() {
    setShowSnackbar(false)
  }

  function doSearch(keywords: string) {
    if (!uid || !shouldDoSearch || !keywords || keywords === '' || keywords.length === 0) {
      setShouldDoSearch(false)
      console.log('Cancelling search')
      return
    }

    setShowSnackbar(false)

    console.log('Submitting Etsy search...')
    utilities.submitEtsySearch({keywords: query}).then((response) => {
      console.log('Etsy results:')
      console.log(response)
      if (response.data.etsy_results.results) {
        const filteredResults: EtsyListingSearchResult[] = response.data.etsy_results.results.filter(
          (result: EtsyListingSearchResult) => result.state === 'active')
        setEtsyResults(filteredResults)
      }
    })
    console.log('Submitting eBay search...')
    utilities.submitSearch(keywords, false, auctionType, preferredSite!,
      selectedCategory, undefined,
      hasManuallySetPrice ? priceMinMax[0].toString() : undefined,
      hasManuallySetPrice ? priceMinMax[1].toString() : undefined,
      preferredCurrency!,
      true).then((response) => {
      console.log('eFerret search response: ')
      console.log(response)
      if (response.data.preview) {
        console.log('Results preview:')
        console.log(response.data.preview)
        if (response.data.preview.error) {
          setSnackbarMessage(response.data.preview.error)
          setShowSnackbar(true)
        }
        if (response.data.preview.results) {
          setSearchResults(response.data.preview.results)
        } else {
          return // Stop if no results (error, etc)
        }
        if (response.data.preview.categoryHistogramContainer) {
          setCategoryHistogram(response.data.preview.categoryHistogramContainer.categoryHistogram)
        }
        const prices = response.data.preview.results.map((result: eFerretSearchResult) => {
          return Number.parseFloat(result.sellingStatus.convertedCurrentPrice.value)
        }).sort((a: number, b: number) => a > b)
        console.log('Min and max prices:')
        console.log(prices[0], prices[prices.length - 1])
        setObservedPriceMinMax([prices[0], prices[prices.length - 1]])
        if (response.data.preview.results.length !== 0) {
          setObservedCurrency(response.data.preview.results[0].sellingStatus.convertedCurrentPrice._currencyId)
        }
      }
    }).finally(() => setShouldDoSearch(false))
  }

  const saveSearch = () => {
    let search = {
      keywords: query,
      searchDescription: false,
      listingType: auctionType,
      siteId: preferredSite!,
      categoryId: selectedCategory,
      minPrice: hasManuallySetPrice ? priceMinMax[0].toString() : undefined,
      maxPrice: hasManuallySetPrice ? priceMinMax[1].toString() : undefined,
      currency: preferredCurrency,
      preview: false
    }
    utilities.submitSearch(
      query,
      false,
      auctionType,
      preferredSite!,
      selectedCategory,
      undefined,
      hasManuallySetPrice ? priceMinMax[0].toString() : undefined,
      hasManuallySetPrice ? priceMinMax[1].toString() : undefined,
      preferredCurrency!,
      false).then((response) => {
        console.log('submitted query')
        console.log(response)
        if (response.data.query_status == 'ok') {
          delete search.preview

          let itemFilters: itemFilter[] = []
          if (hasManuallySetPrice) {
            itemFilters.push({
              name: 'MinPrice',
              value: priceMinMax[0].toString(),
              paramName: 'Currency',
              paramValue: preferredCurrency!
            })
            itemFilters.push({
              name: 'MaxPrice',
              value: priceMinMax[1].toString(),
              paramName: 'Currency',
              paramValue: preferredCurrency!
            })
          }

          let localQuery: eFerretQuery = {
            id: response.data.id,
            results: response.data.results,
            highPriority: false,
            query: {
              keywords: query,
              descriptionSearch: false,
              listingType: auctionType,
              itemFilter: itemFilters
            },
            ...search
          }
          let existingQueriesString = localStorage.getItem('queries')
          let existingQueries: eFerretQuery[] = []
          if (existingQueriesString != null) {
            existingQueries = JSON.parse(existingQueriesString)
          }
          existingQueries.push(localQuery)
          props.setQueryData(existingQueries)
          localStorage.setItem('queries', JSON.stringify(existingQueries))
          props.handleClose()
        } else if (response.data.status === 'error-dialog') {
          console.log('Error dialog:')
          console.log(response.data.error_type)
          let errorType = response.data.error_type
          switch (errorType) {
            case 'error-queries-exceeded':
              setErrorDialogText('You have reached the maximum number of searches. Pause or delete another search to continue.')
              break

            case 'error-high-priority-exceeded':
              setErrorDialogText('You have reached the maximum number of high-priority searches. Pause another search or uncheck "high priority" under Advanced Options.')
              break

            case 'error-low-priority-exceeded':
              setErrorDialogText('You have reached the maximum number of regular-priority searches. Pause another search or check "high priority" under Advanced Options.')
              break

            default:
              setErrorDialogText('There was an error submitting this query. Please try again.')
          }
          setShowErrorDialog(true)
        } else {
          console.log('Error submitting query:')
          console.log(response.data.error)
        }
      }
    )
  }

  if (!uid && !loggingIn) {
    console.log('Not logged in')
    setShouldDoSearch(false)
    setLoggingIn(true)
    utilities.loginAsGuest().then((data) => {
      setLoggingIn(false)
      setShouldDoSearch(true)
    })
  }

  const submitSearch = (event: BaseSyntheticEvent) => {
    console.log('submitSearch()')
    const inputEl = inputRef.current
    if (inputEl) {
      history.push('/search/' + inputEl.value.toString())
    } else {
      history.push('/search/')
    }
    setSearchResults(null)
    setHasManuallySetPrice(false)
    setSelectedCategory(undefined)
    setShouldDoSearch(true)
    event.preventDefault()
  }

  const handleSetAuctionType = (event: React.ChangeEvent<{ value: unknown }>) => {
    setAuctionType(event.target.value as number)
  }

  return (
    <Grid container className={classes.mainWindow}>
      <link rel="preconnect" href="https://i.ebayimg.com" />
      <link rel="preconnect" href="https://thumbs1.ebaystatic.com" />
      <link rel="preconnect" href="https://thumbs2.ebaystatic.com" />
      <link rel="preconnect" href="https://thumbs3.ebaystatic.com" />
      <link rel="preconnect" href="https://thumbs4.ebaystatic.com" />
      {
        props.showTwoColumns ?
          <Grid item xs={2}>
            <Paper className={classes.sidebar} elevation={2}>
              <div>
                <Typography variant='h6'>
                  Price
                </Typography>
                <Divider variant='fullWidth' />
                <PriceSlider observedPrices={observedPriceMinMax} selectedPrices={priceMinMax}
                             setPrices={setPriceMinMax} hasManuallySetPrice={hasManuallySetPrice}
                             setHasManuallySetPrice={setHasManuallySetPrice} observedCurrency={observedCurrency} />
                <ExpansionPanel className={classes.expansionPanel} defaultExpanded>
                  <ExpansionPanelSummary>
                    <Typography variant='h6'>
                      Categories
                    </Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails className={classes.expansionPanelDetails}>
                    <InteractiveCategorySelector categoryHistogram={categoryHistogram}
                                                 selectedCategory={selectedCategory}
                                                 setSelectedCategory={setSelectedCategory} />
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              </div>
            </Paper>
          </Grid>
          :
          ''
      }
      <Grid item container className={classes.resultsView} direction='column' xs={props.showTwoColumns ? 8 : 12}>
        <Grid item>
          <Paper className={classes.searchBarPaper} elevation={4}>
            <form className={classes.searchBarForm} onSubmit={submitSearch}>
              <FormControl>
                <InputLabel id='auction-type-label'>Item types</InputLabel>
                <Select labelId='auction-type-label' value={auctionType} onChange={handleSetAuctionType}
                        variant='standard' className={classes.auctionTypeSelect}>
                  <MenuItem value={0}>Fixed Price</MenuItem>
                  <MenuItem value={1}>Auctions only</MenuItem>
                  <MenuItem value={2}>All Items</MenuItem>
                </Select>
              </FormControl>
              <InputBase className={classes.searchBar} inputRef={inputRef} inputProps={{'aria-label': 'search'}}
                         defaultValue={query} />
              <IconButton type='submit' aria-label='submit search' className={classes.searchSubmitButton}>
                <SearchIcon />
              </IconButton>
            </form>
          </Paper>
        </Grid>
        <Grid item container className={classes.resultsCards} justify='center'>
          <img src='/static/right-now.png' alt='Right now on eBay logo'
               className={classes.rightNowLogo} />
          <InteractiveSearchResults results={searchResults} />
        </Grid>
        {
          searchResults ?
            <Fab variant="extended" color='primary' className={classes.fab} onClick={saveSearch}>
              Save Search&nbsp;
              <NotificationsIcon />
            </Fab>
            :
            ''
        }
      </Grid>
      {
        props.showTwoColumns ?
          <Grid item xs={2}>
            <Paper className={classes.sidebar} elevation={2}>
              {
                etsyResults ?
                  <EtsyResults results={etsyResults!} />
                  :
                  ''
              }
            </Paper>
          </Grid>
          :
          ''
      }
      <Dialog open={showErrorDialog}>
        <DialogTitle>Error</DialogTitle>
        <DialogContent>{errorDialogText}</DialogContent>
        <DialogActions>
          <Button onClick={() => setShowErrorDialog(false)}>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        open={showSnackbar}
        autoHideDuration={10000}
        ContentProps={{
          'aria-describedby': 'message-id'
        }}
        message={<span id="message-id">{snackbarMessage}</span>}
        action={[
          <IconButton
            key="close"
            aria-label="close"
            color="inherit"
            className={classes.closeSnackbarButton}
            onClick={handleCloseSnackbar}
          >
            <CloseIcon />
          </IconButton>
        ]}
      />
    </Grid>
  )
}

export default InteractiveSearch
