import React from 'react'
import { Button, Dialog } from '@material-ui/core'

interface RefinementsButtonProps {
  onClick: () => void
}

const RefinementsButton: React.FC<RefinementsButtonProps> = (props) => {
  return (
    <div>
      <Button onClick={props.onClick}>Add refinements</Button>
    </div>
  )
}

export default RefinementsButton