import React from 'react'
import Card from '@material-ui/core/Card'
import { CardActionArea, createStyles, makeStyles, Theme, Tooltip } from '@material-ui/core'
import { ResultCardProps } from './interfaces'
import TimeListedOverlay from './TimeListedOverlay'
import { printPriceString } from './utilities'
import ReactGA from 'react-ga'
import ItemSpecificsOverlay from './ItemSpecificsOverlay'

ReactGA.initialize('UA-106571309-2', {
  gaOptions: {
    sampleRate: 100
  }
})

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      width: '100%',
      radius: 3,
      boxShadow: '3px',
      paddingBottom: '-4px'
    },
    rightAlignedButton: {
      marginLeft: 'auto'
    },
    actionArea: {
      minHeight: 60,
      display: 'flex',
      alignItems: 'center'
    },
    media: {
      width: '100%',
      verticalAlign: 'middle',
      maxHeight: '100%',
      marginBottom: '-4px'
    }
  })
)

const ResultGridCard: React.FC<ResultCardProps> = (props) => {
  const classes = useStyles()

  function handleClick() {
    ReactGA.event({
      category: 'Conversion',
      action: 'eBay clickthrough',
      label: props.result.itemId
    })
    // @ts-ignore
    window.gtag_report_conversion()
    // @ts-ignore
    window.fbq('track', 'ViewContent', {
      value: 0.50,
      currency: 'USD'
    })
  }

  return (
    <Card className={classes.card}>
      <Tooltip
        title={props.result.title + ' - ' + printPriceString(Number.parseFloat(props.result.sellingStatus.convertedCurrentPrice.value), props.result.sellingStatus.convertedCurrentPrice._currencyId)}>
        <CardActionArea onClick={handleClick} href={props.result.viewItemURL} target='_blank' rel='noopener sponsored'
                        className={classes.actionArea}>
          <img className={classes.media} src={props.result.imageURL} />
          <ItemSpecificsOverlay item={props.result} />
          <TimeListedOverlay timeListed={props.result.listingInfo.startTime} />
        </CardActionArea>
      </Tooltip>
    </Card>
  )
}

export default ResultGridCard