import React from 'react'
import HierarchyView from './HierarchyView'
import CategoryDropdown from './CategoryDropdown'
import { View } from 'react-native'
import * as utilities from './utilities'
import {
  EbayAspectFilter,
  EbayAspectInfo,
  EbayCategoryInfo,
  EbayConditionInfo,
  EbayGenericCategoryInfo,
  EbayTopLevelCategoryInfo
} from './interfaces'
import AspectView from './AspectView'

interface CategorySelectorProps {
  categoryHierarchy: EbayGenericCategoryInfo[],
  setCategoryHierarchy: any,
  aspectFilters: EbayAspectFilter[],
  setAspectFilters: any,
  siteId: string
}

const CategorySelector: React.FC<CategorySelectorProps> = (props) => {
  const [categoryDropdownDisabled, setCategoryDropdownDisabled] = React.useState(false)
  const [chosenAspect, setChosenAspect] = React.useState<null | EbayAspectInfo | 'condition'>(null)
  const [aspectInfo, setAspectInfo] = React.useState<EbayAspectInfo[]>([])
  const [conditionInfo, setConditionInfo] = React.useState<EbayConditionInfo[]>([])
  const [categoryInfo, setCategoryInfo] = React.useState([])
  const [hasActiveRequest, setHasActiveRequest] = React.useState(false)
  const [currentSiteId, setCurrentSiteId] = React.useState(props.siteId)

  if (currentSiteId !== props.siteId) {
    console.log('Detected site ID change, getting categories')
    setChosenAspect(null)
    setAspectInfo([])
    setCategoryInfo([])
    setConditionInfo([])
    setCurrentSiteId(props.siteId)
  }

  let uid = localStorage.getItem('uid')
  if (uid === null) {
    console.log('Not logged in')
    utilities.loginAsGuest().then((data) => {
      getCategories(props.siteId, '-1')
    })
  } else if (categoryInfo.length === 0 && props.categoryHierarchy.length === 0 && !hasActiveRequest) {
    console.log('No category info, fetching')
    getCategories(props.siteId, '-1')
  }

  function addToHierarchy(category: EbayGenericCategoryInfo) {
    let newCategoryHierarchy: EbayGenericCategoryInfo[] = Array.from(props.categoryHierarchy) // Deep copy
    newCategoryHierarchy.push(category)
    console.log(newCategoryHierarchy)
    props.setCategoryHierarchy(newCategoryHierarchy)
    let hierarchyDepth = newCategoryHierarchy.length
    getCategories(props.siteId, newCategoryHierarchy[hierarchyDepth - 1].id)
  }

  function goUp() {
    setCategoryDropdownDisabled(false)
    let newCategoryHierarchy: EbayGenericCategoryInfo[] = Array.from(props.categoryHierarchy) // Deep copy
    newCategoryHierarchy.pop()
    console.log(newCategoryHierarchy)
    props.setCategoryHierarchy(newCategoryHierarchy)
    props.setAspectFilters([])
    setAspectInfo([])
    setChosenAspect(null)
    let hierarchyDepth = newCategoryHierarchy.length
    if (hierarchyDepth === 0) {
      getCategories(props.siteId, '-1')
    } else {
      getCategories(props.siteId, newCategoryHierarchy[hierarchyDepth - 1].id)
    }
  }

  function getCategories(siteId: string, categoryId: string) {
    if (hasActiveRequest) {
      return
    } else {
      setHasActiveRequest(true)
    }

    const toGenericCategoryObject = (category: EbayTopLevelCategoryInfo & EbayCategoryInfo) => {
      if (category.hasOwnProperty('CategoryName')) {
        // Top-level category
        return {
          id: category.CategoryID,
          name: category.CategoryName
        }
      } else {
        return {
          id: category.categoryId,
          name: category.categoryName
        }
      }
    }

    setCategoryInfo([])
    setAspectInfo([])
    setConditionInfo([])

    console.log('Getting categories...')
    utilities.getCategories(siteId, categoryId)
      .then((res) => {
        console.log('API Response:')
        console.log(res)
        // Parse categories
        try {
          if (res.data.results.categories === undefined) {
            // No categories
            console.log('No category info received')
            setCategoryInfo([])
            setCategoryDropdownDisabled(true)
          } else {
            setCategoryDropdownDisabled(false)
            let genericCategories = res.data.results.categories.map(toGenericCategoryObject)
            console.log('Generic categories:')
            console.log(genericCategories)
            setCategoryInfo(genericCategories)
          }
          if (res.data.results.aspects !== undefined) {
            console.log('Received aspects:')
            console.log(res.data.results.aspects)
            setAspectInfo(res.data.results.aspects)
          }
          if (res.data.results.condition !== undefined) {
            console.log('Received condition:')
            console.log(res.data.results.condition)
            let conditionInfo = res.data.results.condition.filter((condition: EbayConditionInfo) => condition.condition.conditionDisplayName !== undefined)
            setConditionInfo(conditionInfo)
          }
        } catch (e) {
          console.log(e)
        }
      }).finally(() =>
      setHasActiveRequest(false)
    )
  }

  return (
    <View>
      <HierarchyView hierarchy={props.categoryHierarchy} />
      <CategoryDropdown categoryInfo={categoryInfo} addToHierarchy={addToHierarchy} goUp={goUp}
                        getCategories={getCategories} disabled={categoryDropdownDisabled} siteId={currentSiteId} />
      <AspectView chosenAspect={chosenAspect} setChosenAspect={setChosenAspect} aspects={aspectInfo}
                  conditionInfo={conditionInfo} aspectFilters={props.aspectFilters}
                  setAspectFilters={props.setAspectFilters} />
    </View>
  )
}

export default CategorySelector
