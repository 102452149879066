import React from 'react'
import { QueriesListProps } from './interfaces'
import { createStyles, Grid, makeStyles, Theme } from '@material-ui/core'
import QueryCard from './QueryCard'
import { getNumberOfNewResultsForQuery } from './utilities'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      height: '100%',
      maxHeight: '100%',
      padding: theme.spacing(2),
      overflowY: 'auto'
    }
  })
)

const QueriesList: React.FC<QueriesListProps> = (props) => {
  const classes = useStyles()

  return (
    <Grid container className={classes.root}>
      <Grid item xs={12}>
        <Grid container spacing={4} justify='space-evenly'>
          {
            props.data.map((query, index) => (
              <Grid key={index} item>
                <QueryCard query={query} setQueryData={props.setQueryData}
                           selectedQuery={props.selectedQuery}
                           setSelectedQuery={props.setSelectedQuery}
                           fillFormFieldsFromQuery={props.fillFormFieldsFromQuery}
                           numNewResults={getNumberOfNewResultsForQuery(query.id)} />
              </Grid>
            ))
          }
        </Grid>
      </Grid>
    </Grid>
  )
}

export default QueriesList