import React, { BaseSyntheticEvent } from 'react'
import { eBayCategoryHistogram, InteractiveCategorySelectorProps } from './interfaces'
import { MenuItem, MenuList, Theme } from '@material-ui/core'
import { makeStyles, createStyles } from '@material-ui/styles'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    menuList: {
      width: '100%',
      padding: 0
    },
    menuItem: {
      fontSize: '0.9em',
      minHeight: 32,
      paddingTop: 2,
      paddingBottom: 2,
      paddingLeft: 8,
      paddingRight: 8,
      overflow: 'hidden'
    }
  })
)

interface InteractiveCategoriesProps {
  categoryHistogram: eBayCategoryHistogram[] | eBayCategoryHistogram
  selectedCategory: string | undefined
  setSelectedCategory: (category: string | undefined) => void
  level: number
}

const InteractiveCategories: React.FC<InteractiveCategoriesProps> = (props) => {
  const classes = useStyles()

  const indent = () => {
    let out = ''
    for (let i = 0; i < props.level; i++) {
      out += ' - '
    }
    return out
  }

  const handleClick = (event: BaseSyntheticEvent) => {
    props.setSelectedCategory(event.target.value.toString())
  }

  return (
    <div>
      {
        Array.isArray(props.categoryHistogram) ?
          (props.categoryHistogram as eBayCategoryHistogram[]).map((categoryHistogram: eBayCategoryHistogram, index) => (
            <div key={index + (props.level * 1000)}>
              <MenuItem className={classes.menuItem} value={categoryHistogram.categoryId}
                        onClick={handleClick} selected={props.selectedCategory === categoryHistogram.categoryId}>
                {indent() + categoryHistogram.categoryName}
              </MenuItem>
              {
                categoryHistogram.childCategoryHistogram ?
                  <InteractiveCategories categoryHistogram={categoryHistogram.childCategoryHistogram}
                                         level={props.level + 1} selectedCategory={props.selectedCategory}
                                         setSelectedCategory={props.setSelectedCategory} />
                  :
                  ''
              }
            </div>
          ))
          :
          <div>
            <MenuItem className={classes.menuItem} value={props.categoryHistogram.categoryId} onClick={handleClick}
                      selected={props.selectedCategory === props.categoryHistogram.categoryId}>
              {indent() + props.categoryHistogram.categoryName}
            </MenuItem>
            {
              props.categoryHistogram.childCategoryHistogram ?
                <InteractiveCategories categoryHistogram={props.categoryHistogram.childCategoryHistogram}
                                       level={props.level + 1} selectedCategory={props.selectedCategory}
                                       setSelectedCategory={props.setSelectedCategory} />
                :
                ''
            }
          </div>
      }
    </div>
  )
}

const InteractiveCategorySelector: React.FC<InteractiveCategorySelectorProps> = (props) => {
  const classes = useStyles()
  const handleClick = (event: BaseSyntheticEvent) => {
    props.setSelectedCategory(undefined)
  }
  return (
    <MenuList className={classes.menuList}>
      <MenuItem onClick={handleClick} className={classes.menuItem} value={0} selected={!props.selectedCategory}>
        All Categories</MenuItem>
      {props.categoryHistogram ?
        <InteractiveCategories categoryHistogram={props.categoryHistogram} level={0}
                               selectedCategory={props.selectedCategory}
                               setSelectedCategory={props.setSelectedCategory} />
        :
        ''
      }
    </MenuList>
  )
}

export default InteractiveCategorySelector