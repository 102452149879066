import React from 'react'
import {
  eFerretSearchResult,
  InteractiveSearchResultCardProps
} from './interfaces'
import { Card, CardActionArea, CardMedia, Theme, CardContent, Typography, Grid } from '@material-ui/core'
import TimeListedOverlay from './TimeListedOverlay'
import { makeStyles, createStyles } from '@material-ui/styles'
import ReactGA from 'react-ga'
import FormattedPrice from './FormattedPrice'
import ItemSpecificsOverlay from './ItemSpecificsOverlay'

ReactGA.initialize('UA-106571309-2', {
  gaOptions: {
    sampleRate: 100
  }
})

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      width: '100%'
    },
    cardMedia: {
      width: '100%',
      paddingTop: '100%',
      position: 'relative'
    },
    cardActionArea: {
      height: '100%'
    },
    listingInfo: {
      fontSize: '0.9em',
      fontFamily: 'roboto, sans-serif',
      fontWeight: 300
    },
    shippingPrice: {
      fontFamily: 'roboto, sans-serif',
      fontSize: '0.7em',
      padding: '8px',
    }
  })
)

const InteractiveSearchResultCard: React.FC<InteractiveSearchResultCardProps> = (props) => {
  const classes = useStyles()
  const handleClick = () => {
    ReactGA.event({
      category: 'Conversion',
      action: 'eBay clickthrough (from preview)',
      label: props.result.itemId
    })
    // @ts-ignore
    window.gtag_report_conversion()
  }

  const printListingInfo = (result: eFerretSearchResult) => {
    const listingInfo = result.listingInfo
    const sellingStatus = result.sellingStatus
    if (listingInfo.listingType === 'FixedPrice' || listingInfo.listingType === 'StoreInventory') {
      if (listingInfo.bestOfferEnabled === 'true') {
        return 'or Best Offer'
      } else {
        return 'Buy It Now'
      }
    } else if (listingInfo.listingType === 'Auction' || listingInfo.listingType === 'AuctionWithBIN') {
      return sellingStatus.bidCount! + ' bids'
    }
  }

  return (
    <Card className={classes.card} elevation={1}>
      <CardActionArea onClick={handleClick} href={props.result.viewItemURL} target='_blank' rel='noopener sponsored'
                      className={classes.cardActionArea}>
        <CardMedia className={classes.cardMedia} image={props.result.imageURL}>
          <ItemSpecificsOverlay item={props.result} />
        </CardMedia>
        <CardContent>
          <Typography variant='body1'>{props.result.title}</Typography>
          <Grid container>
            <Grid item>
              <FormattedPrice currency={props.result.sellingStatus.convertedCurrentPrice._currencyId}
                              amount={props.result.sellingStatus.convertedCurrentPrice.value} />
            </Grid>
            {
              props.result.shippingInfo && props.result.shippingInfo!.shippingServiceCost ?
                <Grid item className={classes.shippingPrice}>
                  +&nbsp;<FormattedPrice currency={props.result.shippingInfo!.shippingServiceCost!._currencyId}
                                  amount={props.result.shippingInfo!.shippingServiceCost!.value} inline /> Shipping
                </Grid>
                :
                ''
            }
          </Grid>
          <div className={classes.listingInfo}>
            {printListingInfo(props.result)}
          </div>
          {
            props.result.listingInfo.listingType === 'AuctionWithBIN' ?
              <div>
                <FormattedPrice currency={props.result.listingInfo.convertedBuyItNowPrice!._currencyId}
                                amount={props.result.listingInfo.convertedBuyItNowPrice!.value} />
                <div className={classes.listingInfo}>
                  Buy It Now
                </div>
              </div>
              :
              ''
          }
        </CardContent>
        <TimeListedOverlay timeListed={props.result.listingInfo.startTime} />
      </CardActionArea>
    </Card>
  )
}

export default InteractiveSearchResultCard