import React, {BaseSyntheticEvent} from 'react'
import {Grid, InputBase, IconButton, Paper, Typography, useMediaQuery} from '@material-ui/core'
import {makeStyles, createStyles, useTheme} from '@material-ui/styles'
import SearchIcon from '@material-ui/icons/Search'
import {useHistory} from 'react-router-dom'
import ReactGA from 'react-ga'
import * as utilities from './utilities'
import createMuiTheme, {Theme} from '@material-ui/core/styles/createMuiTheme'

ReactGA.initialize('UA-106571309-2', {
  gaOptions: {
    sampleRate: 100
  }
})

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    hero: {
      height: '100vh',
      backgroundImage: 'url(/static/landingpage-bg1.jpg)',
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      backgroundColor: '#8d4d38'
    },
    mobileHero: {
      height: '100%',
      backgroundImage: 'url(/static/bg-mobile.jpg)',
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      backgroundColor: '#8d4d38'
    },
    heading: {
      textAlign: 'center',
      color: '#fff',
      fontFamily: 'utopia-std-headline, serif',
      fontDisplay: 'swap',
      fontStyle: 'normal',
      fontWeight: 400,
      margin: '0.2em'
    },
    headingMobile: {
      textAlign: 'center',
      color: '#fff',
      fontFamily: 'utopia-std-headline, serif',
      fontDisplay: 'swap',
      fontSize: '2em',
      fontStyle: 'normal',
      fontWeight: 400,
      margin: '0.2em'
    },
    subheading: {
      textAlign: 'center',
      color: '#fff',
      fontFamily: 'adobe-caslon-pro, serif',
      fontDisplay: 'swap',
      fontStyle: 'normal',
      fontWeight: 600,
      margin: '0.5em'
    },
    subheadingMobile: {
      textAlign: 'center',
      color: '#fff',
      fontFamily: 'adobe-caslon-pro, serif',
      fontDisplay: 'swap',
      fontSize: '1.5em',
      fontStyle: 'normal',
      fontWeight: 600,
      margin: '0.5em'
    },
    searchBar: {
      display: 'flex',
      padding: '2.0em'
    },
    searchBarPaper: {
      padding: '2px 4px',
      display: 'flex',
      alignItems: 'center',
      width: 600,
      justifySelf: 'center',
      borderRadius: 18
    },
    searchBarPaperMobile: {
      padding: '2px 4px',
      display: 'flex',
      alignItems: 'center',
      width: '90%',
      justifySelf: 'center',
      borderRadius: 18
    },
    searchBarForm: {
      flex: 1,
      display: 'flex'
    },
    searchBarInputBase: {
      flex: 1,
      fontSize: '1.5em',
      padding: '0.5em'
    },
    searchBarInputBaseMobile: {
      flex: 1,
      fontSize: '1em',
      paddingLeft: '1em'
    },
    ebayLogoPaper: {
      padding: '5px',
      marginLeft: 'auto',
      marginRight: 'auto',
      maxWidth: 175
    },
    ebayCompatibleLogo: {
      display: 'block',
      marginLeft: 'auto',
      marginRight: 'auto',
      marginTop: '0.9em',
      maxWidth: 175
    },
    ebayCompatibleLogoMobile: {
      display: 'block',
      marginLeft: 'auto',
      marginRight: 'auto',
      marginTop: '0.9em',
      maxWidth: 100
    },
    searchSubmitButton: {
      maxHeight: 48,
      marginTop: 'auto',
      marginBottom: 'auto'
    }
  })
)

const LandingPage: React.FC<{}> = (props) => {
  const classes = useStyles()
  const history = useHistory()
  const inputRef: React.RefObject<HTMLInputElement> = React.createRef()
  const [loggingIn, setLoggingIn] = React.useState(false)
  const theme = createMuiTheme()
  const isMobile = useMediaQuery('(max-width:700px)')


  const submitSearch = (event: BaseSyntheticEvent) => {
    const inputEl = inputRef.current
    if (inputEl) {
      history.push('/search/' + inputEl.value.toString())
      ReactGA.event({
        category: 'Conversion',
        action: 'Submitted search preview from landing page',
        label: inputEl.value.toString()
      })
    } else {
      history.push('/search/')
    }
    event.preventDefault()
  }

  const handleChange = () => {
    const uid = localStorage.getItem('uid')
    if (!uid && !loggingIn) {
      console.log('Logging in as guest')
      setLoggingIn(true)
      utilities.loginAsGuest().then(() => {
        setLoggingIn(false)
      })
    }
  }

  return (
    <Grid container justify='center' className={isMobile ? classes.mobileHero : classes.hero} direction='column'>
      <Grid item>
        <Typography variant='h2' className={isMobile ? classes.headingMobile : classes.heading}>Bargains start
          here</Typography>
      </Grid>
      <Grid item>
        <Typography variant='h4' className={isMobile ? classes.subheadingMobile : classes.subheading}>Shop the world's
          largest online flea
          market.</Typography>
      </Grid>
      <Grid item className={classes.searchBar} justify='center'>
        <Paper className={isMobile ? classes.searchBarPaperMobile : classes.searchBarPaper} elevation={12}>
          <form className={classes.searchBarForm} onSubmit={submitSearch}>
            <InputBase className={isMobile ? classes.searchBarInputBaseMobile : classes.searchBarInputBase}
                       inputProps={{'aria-label': 'search'}} autoFocus={!isMobile} inputRef={inputRef}
                       onChange={handleChange}/>
            <IconButton type='submit' aria-label='submit search' className={classes.searchSubmitButton}>
              <SearchIcon/>
            </IconButton>
          </form>
        </Paper>
      </Grid>
      <Grid item>
        <Paper className={classes.ebayLogoPaper}>
          <img src='/static/compatibleapplication-vert.png'
               alt='eBay Compatible Application logo'
               className={isMobile ? classes.ebayCompatibleLogoMobile : classes.ebayCompatibleLogo}/>
        </Paper>
      </Grid>
      <Grid item style={{textAlign: "center"}}>
        <a
          href='https://play.google.com/store/apps/details?id=io.eferret.eferret&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'><img
          alt='Get it on Google Play'
          style={{
            display: 'inline-block',
            overflow: 'hidden',
            borderRadius: 13,
            width: 250
          }}
          src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'/></a>
      </Grid>
      <Grid item style={{textAlign: "center"}}>
        <a href="https://apps.apple.com/us/app/eferret/id1658695827?itsct=apps_box_badge&amp;itscg=30200"
           style={{
             display: 'inline-block',
             overflow: 'hidden',
             borderRadius: 13,
             width: 215,
             height: 83,
           }}>
          <img
            src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1670976000"
            alt="Download on the App Store" style={{borderRadius: 13, width: 215, height: 83}}/>
        </a>
      </Grid>
      <Grid item>
        <br/>
        <Typography variant='h4' className={classes.subheading}>
          <a style={{color: "inherit", fontSize: '1.25rem'}} href={'mailto:robert@ferretech.io'}>Contact Us</a>
        </Typography>
      </Grid>
      <Grid item>
        <br/>
        <br/>
        <Typography variant='subtitle1' className={classes.subheading}>When you click on links to various merchants on
          this site and make a purchase, this can result in this site earning a commission.</Typography>
        <Typography variant='subtitle1' className={classes.subheading}>Affiliate programs and affiliations include, but
          are not limited to, the eBay Partner Network.</Typography>
      </Grid>
    </Grid>
  )
}

export default LandingPage
